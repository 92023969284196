<template>
    <div class="alerts">
        <div class="container" v-if="loaded">
            <div class="header row">
                <h2 class="row">
                    <p class="blue">{{ pendingAlertCount }}</p>
                    Alerts Pending Review
                </h2>
                <button class="blue link" @click="this.showDownloadModal">Export to CSV</button>
            </div>

            <div class="alert-container">
                <AlertCard
                    class="alert-card"
                    v-for="alert in alertList"
                    :key="alert.id"
                    :alert="alert"
                    :statusUpdating="statusUpdating[alert.id]"
                    @to-student-page="toStudentProfile"
                    @on-status-update="updateStatus"
                />
            </div>
            <Paginator
                v-if="alerts.pagination.next != null"
                :nextPage="alerts.pagination.next != null"
                :loading="alerts.loadingNextPage"
                @next-page="alerts.addNextPage()"
            />
        </div>
        <h1 class="loading-msg" v-show="!loaded">Loading...</h1>
        <ModalReportDownload />
    </div>
</template>

<script>
import { nextTick } from 'vue';

import Alert from '@/services/alerts';

import AlertCard from '@/components-deprecated/admin/AlertCard';
import CollectionManager from '@/services/collectionManager';
import Paginator from '@/components-deprecated/Paginator';
import ModalReportDownload from '@/components-deprecated/admin/ModalReportDownload';

export default {
    name: 'Alerts',
    data() {
        return {
            loaded: false,
            alerts: CollectionManager.create({
                ModelClass: Alert
            }),
            statusUpdating: {}
        };
    },
    components: {
        AlertCard,
        Paginator,
        ModalReportDownload
    },
    mounted() {
        this.getAlertData();
    },
    methods: {
        async getAlertData() {
            this.loaded = false;

            try {
                await Promise.all([
                    // load local list of paginated alerts (pending, action taken, and dismissed alerts)
                    this.alerts.refresh(),
                    // refresh pendingAlertCount in vuex state
                    this.$store.dispatch('loadPendingAlertCount')
                ]);
            } catch (error) {
                this.$Alert.alert({
                    type: 'error',
                    message: `<h2>There was a problem loading this page. Please try again later.</h2>`,
                    timeout: 3000
                });
            } finally {
                this.loaded = true;
            }
        },
        toStudentProfile(id) {
            this.$router.push({ name: 'StudentDetail', params: { id: id } });
        },
        async showDownloadModal() {
            this.$modal.show('modal-report-download', {
                title: 'Export Download Options',
                downloadReport: this.onExport,
                showDeactivatedStudentCheckbox: true,
                showDateRangeInput: true
            });
            nextTick(() => {
                const downloadModal = document.querySelector('.vm--container');
                if (downloadModal) {
                    downloadModal.focus();
                }
            });
        },
        async onExport({ selectedTagCategories, includeDeactivatedStudents, startDate, endDate }) {
            this.loaded = false;
            let tagCategoriesToInclude = '';
            if (selectedTagCategories) {
                tagCategoriesToInclude = selectedTagCategories.map(category => category.id).join();
            }

            await Alert.api.export({ tagCategories: tagCategoriesToInclude, includeDeactivatedStudents, startDate, endDate});
            this.loaded = true;
        },
        async updateStatus(alertId, status, message) {
            try {
                // update the status
                this.statusUpdating[alertId] = true;
                await Alert.api.updateStatus(alertId, status);

                // refresh pendingAlertCount in vuex state
                await this.$store.dispatch('loadPendingAlertCount');

                // update local state
                this.alerts.updateItem(alertId, {
                    alertStatus: status,
                    lastEditedUserRef: { displayName: this.$store.state.user.displayName }
                });

                this.$Alert.alert({
                    type: 'success',
                    message,
                    timeout: 3000
                });
            } catch (error) {
                this.$Alert.alert({
                    type: 'error',
                    message: 'Unable to update alert. Please try again.',
                    timeout: 3000
                });
            } finally {
                this.statusUpdating[alertId] = false;
            }
        }
    },
    computed: {
        alertList() {
            if (this.alerts && this.alerts.list) {
                return this.alerts.list;
            }

            return [];
        },
        pendingAlertCount() {
            return this.$store.state.pendingAlertCount;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.row {
    display: flex;
    flex-direction: row;

    > * {
        margin-right: 0.5rem;
    }
}

.header {
    margin: 2rem;
    justify-content: space-between;
}

.blue {
    color: $primary-brand-color;
}

.link {
    cursor: pointer;
    text-decoration: underline;
    font-size: 1.1rem;
    background: none;
    border: 0;
    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.alerts {
    padding: 1rem;
}

.loading-msg {
    text-align: center;
    padding: 35px;
}

.alert-card {
    padding: 2rem 4rem;
    border: 1px solid $secondary-gray;
    margin: 2rem;
    border-radius: 8px;
}
</style>
