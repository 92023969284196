import axios from 'axios';
import { strictEqual } from 'assert';
import * as Api from './index';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';

export const getStudentConversation = async ({
    studentId,
    page = 1,
    page_size = 25,
    beforeDate = null,
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof studentId, 'string', 'studentId must be a string');
        const url = Api.getv2ApiBase(`/student-message/conversation/student/${studentId}`);
        const defaultOptions = Api.getOptions();
        const params = {
            page,
            page_size
        };

        if (beforeDate) {
            strictEqual(typeof beforeDate, 'string');
            params.beforeDate = beforeDate;
        }

        const response = await axios.get(url, { ...defaultOptions, params });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};
