<template>
    <div class="container">
        <div class="description">
            <h1 class="h2">Interventions</h1>
            <p v-if="touchpointList.length >= 1">
                This is where you see all the actions taken by your chatbot as well as the
                opportunities for you to make an impact with students.
            </p>
        </div>
        <div class="toolbar" v-if="touchpointList.length >= 1">
            <div class="filter-row">
                <template v-if="isExperimentalAccessibilityEnabled">
                    <div class="accessible-filter-wrapper">
                        <DropdownV2
                            :options="filterChoices"
                            :selectedOptionValue="filterValue.id"
                            optionLabelKey="name"
                            optionValueKey="id"
                            label="Filter by Intervention Type"
                            @onChange="onFilter"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="input-container" @click.stop>
                        <button
                            class="input-group cursor-pointer"
                            @click.stop="isDropdownActive = !isDropdownActive"
                        >
                            <label class="input-label faded">
                                {{ filterValue.name }}
                            </label>
                            <div>
                                <svg
                                    width="10px"
                                    height="10px"
                                    class="arrow-reversed"
                                    viewBox="0 0 5.9 17.51"
                                >
                                    <use xlink:href="#icon-arrow-right-wide" />
                                </svg>
                            </div>
                        </button>
                        <TransitionExpand>
                            <div class="transition-container" v-if="isDropdownActive">
                                <button
                                    :key="filterChoice.id"
                                    v-for="filterChoice in filterChoices"
                                    class="dropdown-choice"
                                    @click.stop="onFilter(filterChoice)"
                                >
                                    {{ filterChoice.name }}
                                </button>
                            </div>
                        </TransitionExpand>
                    </div>
                </template>
            </div>
        </div>
        <EmptyState
            v-if="!touchpointList.length && !touchpointsCollection.refreshing"
            message="Nothing to see here... yet!"
            sub-message="Once we start engaging with students, this is where you will see the actions taken by the chatbot as well as the opportunities for you to intervene and make an impact"
        />
        <LoadingSpinner v-if="touchpointsCollection.refreshing" />
        <div v-else class="interventions-page">
            <TouchpointInterventions
                :touchpoints="touchpointList"
                :hasMoreItems="touchpointsCollection.pagination.next != null"
                :loadingNextPage="touchpointsCollection.loadingNextPage"
                :templateTypeFilter="filterValue.id"
                @interventions-next-page="touchpointsCollection.addNextPage()"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import TouchpointInterventions from '@/components-deprecated/overview/TouchpointInterventions';

import TransitionExpand from '@/components-deprecated/TransitionExpand';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import v2CollectionManager from '@/services/v2CollectionManager';
import DropdownV2 from '@/components-deprecated/global/v2/DropdownV2.vue';
import EmptyState from '@/components-deprecated/global/EmptyState';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';

export default {
    name: 'InterventionsPage',
    components: {
        LoadingSpinner,
        TouchpointInterventions,
        TransitionExpand,
        DropdownV2,
        EmptyState
    },

    data() {
        let filterChoices = [
            { name: 'Show All Interventions', id: 'ALL' },
            { name: 'Action Taken by EdSights', id: 'CHATBOT' },
            { name: 'Opportunity for Follow Up', id: 'ADMIN' }
        ];

        return {
            loading: true,
            refreshing: false,
            isDropdownActive: false,

            filterValue: filterChoices[0],
            filterChoices: filterChoices,
            touchpointsCollection: v2CollectionManager.create({
                listType: 'touchpointsWithInterventionStats',
                errorHandlerOptions: {
                    enableAlert: true,
                    overrideMessage:
                        'There was a problem loading the Interventions. Please try again.',
                    rethrow: false
                }
            })
        };
    },

    async created() {
        await this.loadTouchpoints();
        window.addEventListener('click', this.closeEvent);
    },
    beforeUnmount() {
        window.removeEventListener('click', this.closeEvent);
    },
    methods: {
        async loadTouchpoints() {
            this.touchpointsCollection.filters.schoolId = this.user.school;
            this.touchpointsCollection.refresh();
        },
        onFilter(filter) {
            this.filterValue = filter;
            this.touchpointsCollection.filters.interventionTemplateType =
                filter.id === 'ALL' ? null : filter.id;
            this.touchpointsCollection.refresh();
            this.isDropdownActive = false;
        },
        closeEvent() {
            this.isDropdownActive = false;
        }
    },
    computed: {
        ...mapState(['user']),
        touchpointList() {
            if (
                typeof this.touchpointsCollection === 'object' &&
                Array.isArray(this.touchpointsCollection.list)
            ) {
                return this.touchpointsCollection.list;
            }

            return [];
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.interventions-page {
    margin: 0 auto;
    padding-top: 20px;
}

.top-spacing {
    margin-top: 1rem;
}
.table-items {
    display: flex;
}

.interventions-wrapper {
    margin-right: 15px;
    width: 30%;
}

.interventions {
    &__item :deep(.item) {
        min-height: 189px;
    }
}

.students-wrapper {
    width: 65%;
    padding-right: 3rem;

    & :deep(.scroll-wrapper__elem) {
        overflow-y: hidden;
    }

    & :deep(.filters) {
        width: 150px;
        height: calc(100vh - 400px);
        overflow-y: auto;

        &__title {
            position: sticky;
            top: 0;
            z-index: 1;
        }
    }

    & :deep(.list__item) {
        display: block;
        padding: 5px;
        vertical-align: middle;
        min-width: unset;
        font-size: 13px;

        & > * {
            display: inline-block;
        }

        &-checkbox {
            margin-right: 0;
        }

        &-name {
            width: 100px;
        }

        &-email {
            width: 110px;
            margin-right: 5px;
        }

        &-level {
            display: inline-flex;
            margin-right: 0px;
            width: 80px;
        }

        &-driver {
            width: 120px;
        }
    }
}

.students-wrapper h2 {
    margin-top: 3rem;
}

.scroll-wrapper {
    height: calc(100vh - 160px);
}

.loading-msg {
    text-align: center;
    padding: 35px;
}

:deep(.dropdown) {
    // manually setting the style for the dropdown here
    // width is set on the parent class

    .dropdown-input-container {
        width: 100%;
        align-items: center;
        border: 1px solid $secondary-gray;
        border-radius: 5px;

        > .search,
        .selected-items {
            height: 40px;
            border: none;
            background: transparent;

            height: 32px;

            min-width: unset;
            font-size: 14px;
        }
        &.disabled {
            border: 1px solid gray;
        }
    }
}
.filter-row {
    .accessible-filter-wrapper {
        max-width: 300px;
    }

    &__filter {
        display: flex;
        align-items: center;
        * {
            margin: 0 0.5rem;
        }
        label {
            white-space: nowrap;
            font-size: 14px;
            color: black;
            font-weight: bold;
        }
        .dd__school {
            width: 30rem;
            height: 40px;
            display: flex;
            align-items: center;
        }
    }
}

.toolbar {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 2rem 0 0 10rem;
}

.input-container {
    width: 22%;
    position: relative;
    align-items: center;
    margin-right: 2rem;
}

.filter-button {
    margin-right: 1rem;
    color: $accessible-gray;

    &:last-child {
        margin-right: 4rem;
    }
}

.input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $white-blue;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    border: 0;
    height: 32px;
    width: 100%;
    color: $base-font-color;
    font-size: 12px;
    letter-spacing: 1px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }

    > input {
        width: 100%;
        border: none;
        outline: none;
        background-color: inherit;
        padding-right: 3rem;
        font: inherit;
        color: $input-gray;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.input-label {
    cursor: pointer;
    background-color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20rem;
}

.arrow {
    transform: rotate(-90deg);
    &-reversed {
        transform: rotate(90deg);
    }
}

.transition-container {
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    background-color: $white;
    left: 0;
    right: 0;
    max-height: 400px;
}

.dropdown-choice {
    cursor: pointer;
    padding: 0.75rem 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: $base-font-color;
    font-size: 12px;
    letter-spacing: 1px;
    border: 0;
    background: #fff;
    text-align: left;
    margin-bottom: 2px;
    line-height: 26px;

    &:hover {
        background-color: $white-blue;
    }

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.description {
    margin: 3rem 2.75rem;
}
</style>
