<template>
    <div class="student-voice-score-card-container">
        <Card
            :title="title"
            :titleTooltip="titleTooltip"
            :headerButtonText="headerButtonText"
            @headerButtonClick="onHeaderButtonClick"
        >
            <div class="student-voice-score-card-content">
                <div v-if="enableFilters" class="filter-row">
                    <StudentAnalysisFilter
                        :singleSelectTags="true"
                        :showStudentSearch="false"
                        :showTagFilter="true"
                        :showRiskLevelFilter="false"
                        :showRiskDriverFilter="false"
                        :showPhaseFilter="false"
                        :showNumberValidityFilter="false"
                        :showOptInStatusFilter="false"
                        :showActionTakenFilter="false"
                        :showMoreFiltersButton="false"
                        :hideClearButton="true"
                        @filterChanged="filterChanged"
                    />
                    <div v-if="isTagCard && hasScore" class="student-count-tag">
                        {{ calculateStudentCount }}
                    </div>
                </div>
                <LoadingSpinner v-if="loading" />
                <EmptyState
                    v-else-if="showUnfilteredMessage"
                    icon="#icon-no-filter"
                    message="No filter selected"
                    sub-message="Select a tag to view your SVS by Tag and compare the satisfaction levels
                            across different student populations."
                />
                <template v-else-if="showNoFilterResultsMessage">
                    There currently isn't enough data on the selected tag to generate a Tag SVS
                    score. Try again by selecting a different tag with more students.
                </template>
                <template v-else>
                    <div class="data-container">
                        <div>
                            <ScoreProgress
                                :score="score"
                                :comparedScore="comparedScore"
                                :comparedScoreLabel="comparedScoreLabel"
                                :differenceLabelPrefix="differenceLabelPrefix"
                                showScoreGradient
                                showProgressBarColors
                            />
                        </div>
                        <div class="data-container distribution-download">
                            <div>
                                <Distribution
                                    :promoterCount="promoterCount"
                                    :passiveCount="passiveCount"
                                    :detractorCount="detractorCount"
                                />
                            </div>
                            <div class="data-container download" v-if="isMainCard">
                                <ReportRequestDownloadButton
                                    reportType="CHATBOT_CONVERSATIONS"
                                    :ariaLabel="'Download Report'"
                                    :reportOptions="{
                                        chatbotFlowId,
                                        cleanOutput: true
                                    }"
                                />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from '@/components-deprecated/global/Card.vue';
import Distribution from './components/Distribution/Distribution.vue';
import StudentAnalysisFilter from '@/components-deprecated/StudentAnalysisFilter.vue';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import ScoreProgress from '../components/ScoreProgress/ScoreProgress.vue';
import EmptyState from '@/components-deprecated/global/EmptyState';
import ReportRequestDownloadButton from '@/components-deprecated/ReportRequestDownloadButton';

export default {
    name: 'StudentVoiceScoreCard',
    components: {
        StudentAnalysisFilter,
        LoadingSpinner,
        Distribution,
        Card,
        ScoreProgress,
        EmptyState,
        ReportRequestDownloadButton
    },
    props: {
        comparedScore: {
            type: Number,
            required: false
        },
        comparedScoreLabel: {
            type: String,
            required: false
        },
        score: {
            type: Number,
            required: false
        },
        detractorCount: {
            type: Number,
            required: false
        },
        passiveCount: {
            type: Number,
            required: false
        },
        promoterCount: {
            type: Number,
            required: false
        },
        enableFilters: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Student Voice Score'
        },
        titleTooltip: {
            type: String,
            default: "Your school's latest SVS"
        },
        loading: {
            type: Boolean,
            default: false
        },
        headerButtonText: {
            type: String,
            required: false
        },
        differenceLabelPrefix: {
            type: String,
            required: false
        },
        differenceLabelSuffix: {
            type: String,
            required: false
        },
        isTagCard: {
            type: Boolean,
            default: false
        },
        isMainCard: {
            type: Boolean,
            default: false
        },
        chatbotFlowId: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            appliedFilter: null
        };
    },
    methods: {
        filterChanged(filter) {
            this.appliedFilter = filter;
            this.$emit('filterChanged', filter);
        },
        onHeaderButtonClick() {
            this.$emit('headerButtonClick');
        }
    },
    computed: {
        // Note: The filtering component we're using supports many filters, but we're only using
        // tagIds, so we only check tagIds here.
        hasAppliedFilters() {
            return this.appliedFilter !== null && this.appliedFilter.tagIds;
        },
        hasScore() {
            return typeof this.score === 'number';
        },
        showUnfilteredMessage() {
            return !this.loading && this.enableFilters && !this.hasAppliedFilters && !this.hasScore;
        },
        showNoFilterResultsMessage() {
            return !this.loading && this.enableFilters && this.hasAppliedFilters && !this.hasScore;
        },
        calculateStudentCount() {
            // Use the nullish coalescing operator (??) to default to 0 if the prop is null or undefined
            const promoterCount = this.promoterCount ?? 0;
            const passiveCount = this.passiveCount ?? 0;
            const detractorCount = this.detractorCount ?? 0;

            const studentCount = promoterCount + passiveCount + detractorCount;
            return `${studentCount} student(s) responded in this tag`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.student-voice-score-card-container {
    :deep(.card-container) {
        display: flex;
        flex-direction: column;
    }
    :deep(.card-content) {
        flex: 1;
        display: flex;
    }
    .student-voice-score-card-content {
        width: 100%;
        .student-analysis-filter {
            align-items: flex-start;
            flex-direction: row;
            width: 100%;
            justify-content: flex-end;
        }
    }
}
.data-container {
    display: flex;
    flex-direction: column;

    &.distribution-download {
        flex-direction: row;
        flex: 1;
        align-items: flex-start;
    }

    &.download {
        margin-left: auto;
        margin-top: auto;
        padding: 0 0 0.5rem;
    }
}
</style>
