<template>
    <div class="interventions">
        <BoxContainer
            title="Interventions"
            :subtitle="subtitle"
            emptyDescription="Once we start engaging with your students, you will see what our chatbot did, as well as any suggested interventions here."
            :showMoreTo="showMoreTo"
            :ariaLabel="ariaLabel"
            fixedHeader
        >
            <div
                v-if="interventions.length !== 0 || displayEmptyMsgAsListItem"
                class="interventions__items"
            >
                <template v-if="interventions.length !== 0">
                    <slot
                        v-for="intervention in interventions"
                        name="intervention"
                        v-bind="intervention"
                    >
                        <!-- Fallback content, if nothing is passed into Interventions component slot (i.e. the home page) -->

                        <OverviewListItem
                            v-if="intervention.type === 'CHATBOT'"
                            :interventionTypeId="intervention.id"
                            :key="intervention.id"
                            :title="'Action taken by Edsights'"
                            :description="intervention.renderedText"
                            :date="intervention.mostRecentInterventionDate"
                            :linkText="
                                _showChatHistoryButton(intervention) ? 'View Chat Detail' : null
                            "
                            :link2Text="
                                showChatHistoryButton
                                    ? 'Go to Intervention'
                                    : 'View Intervention details'
                            "
                            :ariaLabel="'Action taken by Edsights:'"
                            @clickLink="openChatHistory(intervention)"
                            @clickLink2="openInterventionDetails(intervention)"
                        >
                            <template v-slot:icon v-if="!isExperimentalAccessibilityEnabled">
                                <img
                                    class="icon"
                                    src="@/assets/icons/robot.png"
                                    alt=""
                                    aria-hidden="true"
                                />
                            </template>
                        </OverviewListItem>
                        <OverviewListItem
                            v-else
                            :interventionTypeId="intervention.id"
                            :key="intervention.id"
                            :title="'Opportunity for Follow Up ✨'"
                            :description="intervention.renderedText"
                            :date="intervention.mostRecentInterventionDate"
                            :linkText="
                                _showChatHistoryButton(intervention) ? 'View Chat Detail' : null
                            "
                            :link2Text="
                                showChatHistoryButton
                                    ? 'Go to Intervention'
                                    : 'View Intervention details'
                            "
                            :ariaLabel="'Opportunity for Follow Up:'"
                            @clickLink="openChatHistory(intervention)"
                            @clickLink2="openInterventionDetails(intervention)"
                        >
                            <template v-slot:icon v-if="!isExperimentalAccessibilityEnabled">
                                <img
                                    class="icon"
                                    src="@/assets/icons/lightbulb.png"
                                    aria-hidden="true"
                                />
                            </template>
                        </OverviewListItem>
                    </slot>
                </template>
                <OverviewListItem
                    v-else-if="displayEmptyMsgAsListItem"
                    title="Coming Soon!"
                    description="Suggested interventions, as well as actions taken by EdSights, will be displayed here as we learn more about your students."
                    selected
                />
                <Paginator
                    v-if="hasMoreItems"
                    :loading="loadingNextPage"
                    :nextPage="hasMoreItems"
                    @next-page="$emit('interventions-next-page')"
                />
            </div>
        </BoxContainer>
    </div>
</template>

<script>
import OverviewListItem from '@/components-deprecated/overview/OverviewListItem';
import BoxContainer from '@/components-deprecated/BoxContainer';
import Paginator from '@/components-deprecated/Paginator';
import InterventionItem from '@/services/interventionItems';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';
import { DateTime } from 'luxon';
import { isDateAfterNextGenBotRelease } from '@/lib/dates';

export default {
    name: 'InterventionsWidget',

    components: {
        OverviewListItem,
        BoxContainer,
        Paginator
    },

    props: {
        hasMoreItems: {
            type: Boolean,
            default: false
        },
        interventions: {
            type: Array,
            default: () => []
        },
        showMoreTo: {
            type: Object,
            default: null
        },
        displayEmptyMsgAsListItem: {
            type: Boolean,
            default: false
        },
        loadingNextPage: {
            type: Boolean,
            default: false
        },
        pageName: {
            type: String,
            default: 'Interventions'
        },
        subtitle: {
            type: String,
            default: 'Review Interventions'
        },
        ariaLabel: {
            type: String,
            default: 'Full Page - Review Interventions'
        },
        showChatHistoryButton: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showCustomDate() {
            /*
      If the interventions being shown are on the student detail page
      then show the date the student was added to the intervention
      Rather than the most recent intervention date added to the template as requested by C&C
      */
            return this.$route.name === 'StudentDetail';
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    },
    methods: {
        _showChatHistoryButton(intervention) {
            return this.showChatHistoryButton && this.isDateAddedPostNextGenBotDate(intervention);
        },
        isDateAddedPostNextGenBotDate(intervention) {
            if (!intervention?.mostRecentInterventionDate) {
                return false;
            }

            return isDateAfterNextGenBotRelease(intervention.mostRecentInterventionDate);
        },
        async getDateAdded(interventionId) {
            // get the time of the intervention added to the intervention template

            let { results } = await InterventionItem.api.list({
                student: this.$route.params.id,
                template: interventionId
            });

            let intervention = results[0];
            if (intervention) {
                return intervention.dateAdded;
            } else {
                // this was erroring out when a dateAdded didnt exist, this prevents that
                return '';
            }
        },
        openChatHistory(intervention) {
            this.$emit('openChatHistory', intervention);
        },
        openInterventionDetails(intervention) {
            this.$router.push({
                name: 'InterventionDetails',
                params: {
                    id: intervention.id
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.interventions {
    position: relative;
    height: 100%;
}

.icon {
    height: 40px;
    width: 40px;
}
</style>
