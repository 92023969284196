<template>
    <div class="container student-voice-score-page-container">
        <LoadingSpinner v-if="loading" />
        <div v-else-if="hasScores" class="cards-container">
            <StudentVoiceScoreCard
                :score="latestStudentVoiceScore.score"
                :promoterCount="latestStudentVoiceScore.promoterCount"
                :passiveCount="latestStudentVoiceScore.passiveCount"
                :detractorCount="latestStudentVoiceScore.detractorCount"
                :comparedScore="globalStudentVoiceScoreAverage"
                comparedScoreLabel="National Average"
                differenceLabelPrefix="Your SVS is"
                headerButtonText="Compare to peer institutions"
                @headerButtonClick="toggleInstitutionTypesModal"
                :chatbotFlowId="latestStudentVoiceScore.chatbotFlowId"
                :isMainCard="true"
            />
            <StudentVoiceScoreOverTimeCard :studentVoiceScores="studentVoiceScores" />
            <StudentVoiceScoreCard
                title="Score by Tag"
                titleTooltip="Your school's latest SVS for a particular tag"
                :enableFilters="true"
                :score="latestStudentVoiceScoreByTag.score"
                :promoterCount="latestStudentVoiceScoreByTag.promoterCount"
                :passiveCount="latestStudentVoiceScoreByTag.passiveCount"
                :detractorCount="latestStudentVoiceScoreByTag.detractorCount"
                :comparedScore="latestStudentVoiceScore.score"
                comparedScoreLabel="Your Overall SVS"
                differenceLabelPrefix="This Tag's SVS is"
                @filterChanged="filterChanged"
                :loading="svsByTags.loading"
                :isTagCard="true"
            />
            <StudentVoiceScoreInsights />
        </div>
        <EmptyState
            v-else
            subMessage="Once we've sent out the SVS flow and have collected enough responses from students, you will see your Student Voice Score here."
        />
        <StudentVoiceScoreByInstitutionTypeModal
            v-if="showSvsByInstitutionTypeModal"
            :score="latestStudentVoiceScore.score"
            @close="toggleInstitutionTypesModal"
        />
    </div>
    <ModalReportDownload />
</template>

<script>
import StudentVoiceScoreCard from '@/views/StudentVoiceScorePage/components/StudentVoiceScoreCard/StudentVoiceScoreCard.vue';
import {
    getStudentVoiceScoreSummaryBySchoolId,
    getStudentVoiceScoreBySchoolIdAndTagId
} from '@/api/student-voice-score';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import { strictEqual } from 'assert';
import StudentVoiceScoreOverTimeCard from '@/views/StudentVoiceScorePage/components/StudentVoiceScoreOverTimeCard/StudentVoiceScoreOverTimeCard.vue';
import StudentVoiceScoreByInstitutionTypeModal from '@/views/StudentVoiceScorePage/components/StudentVoiceScoreByInstitutionTypeModal/StudentVoiceScoreByInstitutionTypeModal.vue';
import { roundSvsScore } from '@/views/StudentVoiceScorePage/helpers/svs';
import EmptyState from '@/components-deprecated/global/EmptyState.vue';
import StudentVoiceScoreInsights from '@/views/StudentVoiceScorePage/components/StudentVoiceScoreInsightsCard/StudentVoiceScoreInsightsCard.vue';
import ModalReportDownload from '@/components-deprecated/admin/ModalReportDownload';

export default {
    name: 'StudentVoiceScorePage',
    components: {
        EmptyState,
        LoadingSpinner,
        StudentVoiceScoreCard,
        StudentVoiceScoreInsights,
        StudentVoiceScoreOverTimeCard,
        StudentVoiceScoreByInstitutionTypeModal,
        ModalReportDownload
    },
    data() {
        return {
            loading: false,
            svsSummary: {
                studentVoiceScores: null,
                globalStudentVoiceScoreAverage: null
            },
            // SVS by tag works for a single tag only, but we are anticipating more tags in the
            // future, plus viewing SVS by tag over time, hence the plural naming:
            svsByTags: {
                loading: false,
                studentVoiceScores: null
            },
            showSvsByInstitutionTypeModal: false
        };
    },
    async created() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            try {
                this.loading = true;
                const summary = await getStudentVoiceScoreSummaryBySchoolId({
                    schoolId: this.$store.state.user.schoolRef.id,
                    errorHandlerOptions: {
                        rethrow: true,
                        enableAlert: false,
                        overrideMessage: 'Unable to load SVS data.'
                    }
                });

                strictEqual(typeof summary, 'object');
                strictEqual(
                    Array.isArray(summary.studentVoiceScores) &&
                        summary.studentVoiceScores.every(svs => svs && typeof svs === 'object'),
                    true
                );
                strictEqual(typeof summary.globalStudentVoiceScoreAverage, 'object');

                this.svsSummary = {
                    studentVoiceScores: summary.studentVoiceScores
                        .sort((a, b) => new Date(a.chatbotFlowDate) - new Date(b.chatbotFlowDate))
                        .map(svs => this.formatSvsObject(svs)),
                    globalStudentVoiceScoreAverage: {
                        ...summary.globalStudentVoiceScoreAverage,
                        scoreAverage: roundSvsScore(
                            summary.globalStudentVoiceScoreAverage.scoreAverage
                        )
                    }
                };
            } catch (error) {
                console.error(error);
                this.$Alert.alert({
                    type: 'error',
                    message: 'Unable to load SVS data.',
                    timeout: 20000
                });
            } finally {
                this.loading = false;
            }
        },
        async filterChanged(filter) {
            this.svsByTags.loading = true;
            this.svsByTags.studentVoiceScores = null;

            try {
                if (filter && filter.tagIds) {
                    const svs = await getStudentVoiceScoreBySchoolIdAndTagId({
                        schoolId: this.$store.state.user.schoolRef.id,
                        tagId: filter.tagIds,
                        errorHandlerOptions: {
                            rethrow: true,
                            enableAlert: false
                        }
                    });
                    if (svs) {
                        this.svsByTags.studentVoiceScores = [this.formatSvsObject(svs)];
                    }
                }
            } catch (error) {
                let showAlert = true;

                // Skip the alert on 404s since the UI renders a "not found" message instead.
                if (error.response && error.response.status === 404) {
                    showAlert = false;
                }

                if (showAlert) {
                    this.$Alert.alert({
                        type: 'error',
                        message: 'There was a problem while loading SVS.',
                        timeout: 10000
                    });
                }
            } finally {
                this.svsByTags.loading = false;
            }
        },
        toggleInstitutionTypesModal() {
            this.showSvsByInstitutionTypeModal = !this.showSvsByInstitutionTypeModal;
        },
        // formats a svs object returned from the API for use with this page:
        formatSvsObject(svs) {
            strictEqual(typeof svs, 'object', 'svs must be an object');
            return {
                ...svs,
                score: roundSvsScore(svs.score)
            };
        }
    },
    computed: {
        studentVoiceScores() {
            if (this.svsSummary && Array.isArray(this.svsSummary.studentVoiceScores)) {
                return this.svsSummary.studentVoiceScores;
            }

            return null;
        },
        studentVoiceScoresByTag() {
            if (this.svsByTags && Array.isArray(this.svsByTags.studentVoiceScores)) {
                return this.svsByTags.studentVoiceScores;
            }

            return null;
        },
        latestStudentVoiceScore() {
            const svs = {
                score: null,
                promoterCount: null,
                passiveCount: null,
                detractorCount: null,
                chatbotFlowId: null
            };

            if (this.hasScores) {
                const latest = this.studentVoiceScores[this.studentVoiceScores.length - 1];
                svs.score = latest.score;
                svs.promoterCount = latest.promoterCount;
                svs.passiveCount = latest.passiveCount;
                svs.detractorCount = latest.detractorCount;
                svs.chatbotFlowId = latest.chatbotFlowId;
            }

            return svs;
        },
        latestStudentVoiceScoreByTag() {
            const svs = {
                score: null,
                promoterCount: null,
                passiveCount: null,
                detractorCount: null
            };

            if (
                Array.isArray(this.studentVoiceScoresByTag) &&
                this.studentVoiceScoresByTag.length > 0
            ) {
                const latest = this.studentVoiceScoresByTag[
                    this.studentVoiceScoresByTag.length - 1
                ];
                svs.score = latest.score;
                svs.promoterCount = latest.promoterCount;
                svs.passiveCount = latest.passiveCount;
                svs.detractorCount = latest.detractorCount;
            }

            return svs;
        },
        globalStudentVoiceScoreAverage() {
            if (this.svsSummary && this.svsSummary.globalStudentVoiceScoreAverage) {
                return this.svsSummary.globalStudentVoiceScoreAverage.scoreAverage;
            }

            return null;
        },
        hasScores() {
            return Array.isArray(this.studentVoiceScores) && this.studentVoiceScores.length;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.student-voice-score-page-container {
    height: 100%;
    width: 100%;
    $gap-size: 20px;

    :deep(.card-container) {
        min-height: 310px;
    }
    .cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: $gap-size;

        // Cards (currently 4)
        & > * {
            width: calc(50% - #{$gap-size});
            // Take full width on smaller screens.
            flex: 1 1 100%;

            // Adjust for 2 columns layout on larger screens.
            @media (min-width: 950px) {
                flex: 1 1 calc(50% - #{$gap-size});
            }
        }
    }
}
</style>
