<template>
    <div class="row">
        <slot name="icon"></slot>
        <div class="item-wrapper" :class="{ 'item-wrapper_selected': selected === true }">
            <div
                class="item"
                role="group"
                :aria-label="title"
                :class="{ 'item_hidden-border': hideBorder }"
            >
                <header class="item__header">
                    <slot name="header">
                        <h3>{{ formattedTitle }}</h3>
                        <h3 v-if="date != ''" class="item__date">{{ dateToRender }}</h3>
                    </slot>
                </header>
                <div class="item__content" @click="onClickDescription">
                    <ul class="item__flex" v-if="resourceSubsections || resourceSectionDescription">
                        <li v-for="subSection in resourceSubsections">
                            <button
                                class="item__description"
                                :class="
                                    selectedSubSectionName === subSection.subSection
                                        ? 'item__description--active'
                                        : ''
                                "
                                :aria-current="
                                    selectedSubSectionName === subSection.subSection
                                        ? 'true'
                                        : 'false'
                                "
                                @click="
                                    onClickResourceSubsection(
                                        subSection,
                                        resourceSectionDescription
                                    )
                                "
                                :key="subSection.subSection"
                            >
                                {{ subSection.subSection }}
                            </button>
                        </li>
                    </ul>
                    <span class="item__description">{{ formattedDescription }}</span>
                </div>
                <Button
                    v-if="buttonTo != null || buttonText != null"
                    :to="buttonTo"
                    class="item__button-left"
                    @click="onClickPrimaryButton"
                    >{{ buttonText }}</Button
                >
                <router-link v-if="linkTo != null" :to="linkTo" class="item__link text-gray">
                    {{ linkText }}
                </router-link>
                <div class="link-row">
                    <a
                        :aria-label="ariaLabel + ' ' + dateToRender"
                        v-if="linkText"
                        href="#"
                        class="item__link text-gray"
                        @click.prevent="onClickLink"
                        >{{ linkText }}</a
                    >
                    <a
                        :aria-label="ariaLabel + ' ' + dateToRender"
                        v-if="link2Text"
                        href="#"
                        class="item__link text-gray"
                        @click.prevent="onClickLink2"
                        >{{ link2Text }}</a
                    >
                </div>
            </div>
            <div v-if="!hideBottomBorder" class="line-after"></div>
        </div>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';
import moment from 'moment';
import { formatAccessibleString } from '@/lib/string-helpers';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';

export default {
    name: 'OverviewListItem',

    components: {
        Button
    },

    props: {
        icon: {
            type: String,
            default: null
        },
        interventionType: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        date: {
            type: String,
            default: ''
        },
        linkTo: {
            type: Object,
            default: null
        },
        buttonTo: {
            type: Object,
            default: null
        },
        buttonSecondaryTo: {
            type: Object,
            default: null
        },
        buttonText: {
            type: String,
            default: null
        },
        linkText: {
            type: String,
            default: null
        },
        link2Text: {
            type: String,
            default: null
        },
        ariaLabel: {
            type: String,
            default: null
        },
        selected: {
            type: Boolean,
            default: false
        },
        hideBorder: {
            type: Boolean,
            default: false
        },
        hideBottomBorder: {
            type: Boolean,
            default: false
        },
        resourceSubsections: {
            type: Array,
            default: null
        },
        resourceSectionDescription: {
            type: String,
            default: null
        },
        selectedSubSectionName: {
            type: String,
            default: null
        }
    },
    computed: {
        dateToRender() {
            return moment(this.date).format('ll');
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        },
        formattedTitle() {
            if (!this.title) {
                return '';
            }

            return formatAccessibleString(this.title, this.isExperimentalAccessibilityEnabled);
        },
        formattedDescription() {
            if (!this.description) {
                return '';
            }

            return formatAccessibleString(
                this.description,
                this.isExperimentalAccessibilityEnabled
            );
        }
    },

    methods: {
        onClickPrimaryButton() {
            this.$emit('click');
        },
        onClickSecondaryButton() {
            this.$emit('clickSecondary');
        },
        onClickLink() {
            this.$emit('clickLink');
        },
        onClickLink2() {
            this.$emit('clickLink2');
        },
        onClickDescription() {
            this.$emit('clickDescription');
        },
        onClickResourceSubsection(subsection, description) {
            this.$emit('clickResourceSubsection', subsection, description);
            // this.selected = true
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.row {
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
}

.item-wrapper {
    padding: 0 1rem;
    flex: 1;

    &:last-child {
        .item {
            border-bottom: 0;
        }
    }

    &_selected {
        background-color: $primary-brand-color-very-light;
    }
}

.item {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__content {
        margin: 0.25rem 0.5rem 1.5rem 0;
    }

    .link-row {
        display: flex;
        justify-content: space-between;
    }

    &__date {
        min-width: 100px;
        text-align: right;
        color: $accessible-gray;
    }

    &__button-left {
        min-width: 115px;

        &:focus {
            outline: 2px solid $edsights-blue;
            outline-offset: 2px;
        }
    }

    &__button-right {
        min-width: 115px;
        margin-left: 1rem;
        height: 38px;
    }

    &__link {
        margin-top: 15px;
    }

    &_hidden-border {
        border-bottom: 0;
    }

    &__flex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        li {
            list-style-type: none;
        }
    }

    &__description {
        background: none;
        border: 0;
        margin-bottom: 0.5rem;
        &--active {
            text-decoration: underline;
        }

        &:focus {
            outline: 2px solid $edsights-blue;
        }
    }
}

.line-after {
    border-top: 1px solid $border-color;
    width: 90%;
    margin: auto;
    margin-top: 1.5rem;
}
</style>
