<template>
    <div class="intervention-details">
        <button class="row clickable" @click="navigateBack">
            <svg width="14px" height="14px" class="arrow" viewBox="0 0 5.9 17.51">
                <use xlink:href="#icon-arrow-right-wide" />
            </svg>
            <span class="back-link">Back to {{ previousPageName }}</span>
        </button>

        <div
            v-if="chatbotTemplate && interventionIndex && interventionCount"
            class="row flow-name-row"
        >
            <span class="flow-name">
                {{ formattedChatbotTemplateName }}
                <span class="intervention-count"
                    >(Intervention {{ interventionIndex }} of {{ interventionCount }})
                </span>
            </span>
        </div>

        <div v-if="interventionTemplate != null && chatbotTemplate && chatbotFlow">
            <InterventionCard
                :interventionTemplate="interventionTemplate"
                :showDetailsLink="false"
                :showDownloadReport="chatbotTemplate.createReport"
                :chatbot-flow="chatbotFlow"
                :reportOptions="{
                    chatbotFlowId: chatbotFlow.id,
                    touchpointId: chatbotTemplate.touchpoint,
                    interventionTemplateId: interventionTemplate.id,
                    cleanOutput: true
                }"
            />
        </div>
        <LoadingSpinner v-else />

        <div class="analysis">
            <ModalCreateMassNote @create-mass-note="createMassNote" />
            <div class="analysis-content">
                <StudentsList
                    class="box list"
                    :students="students"
                    :total-students-count="totalStudentCount"
                    :hide-filter="hideFilter"
                    :initial-search-query="initialSearchQuery"
                    @create-mass-note="openModalCreateNote"
                    @filter="onChangeFilter"
                    @email="onEmail"
                    @export="onExport"
                    @markActionTaken="onOutreach"
                    @students-next-page="getNextStudents"
                    @chatClick="onChatClick"
                    :loading-students="nextPageLoading"
                    :hasMoreStudents="interventionItems.pagination.next != null"
                    :pagination-loading="nextPageLoading"
                    :loadingActions="actionsLoading"
                    :showDateAdded="true"
                    :showOutreachStatus="showOutreachStatus"
                    :showViewChat="showViewChat"
                    :showStudentSearch="true"
                    :showPhaseFilter="false"
                    :showNumberValidityFilter="false"
                    :showOptInStatusFilter="false"
                    :showActionTakenFilter="showActionTakenFilter"
                    :showMoreFiltersButton="true"
                />
            </div>

            <risk-driver-info-modal ref="RiskDriverInfoModal"></risk-driver-info-modal>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import copy from 'copy-to-clipboard';

import InterventionCard from '@/components-deprecated/overview/InterventionCard';
import ModalCreateMassNote from '@/components-deprecated/admin/ModalCreateMassNote';
import RiskDriverInfoModal from '@/components-deprecated/RiskDriverInfoModal';
import StudentsList from '@/components-deprecated/tables/StudentsList';

import ChatbotTemplate from '@/services/chatbotTemplates';
import ChatbotFlow from '@/services/chatbotFlows';
import Note from '@/services/notes';
import Student from '@/services/students';

import moment from 'moment';
import {
    bulkMarkActionTaken,
    findInterventionTemplateById,
    listStudentsByInterventionTemplateId
} from '@/api/intervention-template';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import {
    isExperimentalAccessibilityEnabled,
    isInterventionDetailChatHistoryEnabled
} from '@/lib/feature-flag';
import { formatAccessibleString } from '@/lib/string-helpers';
import { getters } from '@/lib/app-cache';

export default {
    name: 'InterventionDetails',
    components: {
        LoadingSpinner,
        InterventionCard,
        ModalCreateMassNote,
        RiskDriverInfoModal,
        StudentsList
    },
    inject: ['openStudentChatHistoryDrawer'],
    data() {
        return {
            loading: false,
            interventionTemplate: null,
            enableChatHistory: false,
            chatbotTemplate: null,
            chatbotFlow: null,
            interventionIndex: 0,
            interventionCount: 0,
            previousPageName: 'Interventions',
            // Tags loaded from server
            tags: [],
            // Items of selected filter groups
            // Used to display groups expanded
            selectedFilterGroups: [],
            // Currently applied api params
            params: {},
            tagId: null,
            // Additional filters

            filters: Student.options.FILTERS,
            // Initial search query, which will be autopopulated to the seach input
            initialSearchQuery: this.$route.params.searchQuery,
            interventionItems: {
                count: 0,
                pagination: {},
                students: []
            },
            actionsLoading: false,
            nextPageLoading: false
        };
    },

    computed: {
        ...mapState(['user', 'staffSchoolViewEnabled']),
        hideFilter() {
            return this.tagId != null;
        },
        totalStudentCount() {
            return this.interventionItems.count;
        },
        students() {
            return this.interventionItems.students;
        },
        showOutreachStatus() {
            if (!this.interventionTemplate) {
                return false;
            }

            return this.interventionTemplate.type === 'ADMIN';
        },
        showActionTakenFilter() {
            if (!this.interventionTemplate) {
                return false;
            }

            return this.interventionTemplate.type === 'ADMIN';
        },
        isLimitedAdmin() {
            return (
                typeof this.user === 'object' &&
                typeof this.user.adminAccount === 'object' &&
                this.user.adminAccount.type === 'LIMITED'
            );
        },
        // formats this.params for use with this.loadStudents and this.onOutreach.
        // this filtering must be kept in sync, so that calling this.onOutreach({ isAllSelected: true }) only updates the filtered students
        formattedFilterParams() {
            return {
                overallRiskLevel: this.params.overallRiskLevel,
                academicRiskLevel: this.params.academicRiskLevel,
                financialRiskLevel: this.params.financialRiskLevel,
                engagementRiskLevel: this.params.engagementRiskLevel,
                wellnessRiskLevel: this.params.wellnessRiskLevel,
                tags: this.params.tags,
                outreachStatus: this.params.outreach_status,
                search: this.params.search
            };
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        },
        formattedChatbotTemplateName() {
            if (!this.chatbotTemplate || !this.chatbotTemplate.name) {
                return '';
            }

            return formatAccessibleString(
                this.chatbotTemplate.name,
                this.isExperimentalAccessibilityEnabled
            );
        },
        showViewChat() {
            return this.enableChatHistory;
        }
    },
    async created() {
        await Promise.all([this.loadInterventionTemplate(), this.loadContext()]);

        this.applyTag();
        this.applySearchQuery();

        if (this.staffSchoolViewEnabled) {
            this.params.school = this.user.school;
        }
        this.params.template = this.$route.params.id;

        this.loadStudents();
        this.enableChatHistory = await isInterventionDetailChatHistoryEnabled(
            this.user.schoolRef.id
        );
    },
    mounted() {
        this.$emit('shouldShowSchools', true);
    },
    methods: {
        ...mapActions(['updateBackgroundOperation']),

        navigateBack() {
            this.$router.go(-1);
        },

        async getNextStudents() {
            this.nextPageLoading = true;
            const interventionTemplateId = this.$route.params.id && String(this.$route.params.id);
            const nextPagedStudents = await listStudentsByInterventionTemplateId({
                interventionTemplateId,
                paginationUrl: this.interventionItems.pagination.next
            });
            if (
                typeof nextPagedStudents === 'object' &&
                Array.isArray(nextPagedStudents.students)
            ) {
                this.interventionItems = {
                    ...nextPagedStudents,
                    students: [...this.interventionItems.students, ...nextPagedStudents.students]
                };
            }
            this.nextPageLoading = false;
        },
        /**
         * Applies tag to the serach filter from params of the current URL
         */
        applyTag() {
            this.tagId = this.$route.query.tag;
            this.params = { tagIds: this.tagId };
        },
        /**
         * Applies search query to the serach filter from params of the current URL
         */
        applySearchQuery() {
            // If search term was passed in, apply search filter
            const search = this.initialSearchQuery;
            if (this.params == null) {
                this.params = { search };
            } else {
                this.params.search = search;
            }
        },
        /**
         * @param {Object} event
         * @param {String} event.search - search query applied
         * @param {Array} event.filters - array of filters applied. See `filters` poperty on the current component
         *                                and tags retrieved from the server side and selected in `StudentsLIst` component
         */
        onChangeFilter(filters) {
            if (this.staffSchoolViewEnabled) {
                filters.school = this.user.school;
            }

            this.params = filters;

            this.params.tags = filters.tagIds;
            delete this.params.tagIds;

            if (this.showActionTakenFilter) {
                this.params.outreach_status = filters.actionTaken;
                delete this.params.actionTaken;
            }

            this.loadStudents();
        },
        onChatClick(studentId) {
            if (studentId) {
                this.openStudentChatHistoryDrawer({
                    studentId,
                    scrollToInterventionTemplateId: this.interventionTemplate.id
                });
            }
        },
        async loadStudents() {
            const filterParams = this.formattedFilterParams;

            const interventionTemplateId = this.$route.params.id && String(this.$route.params.id);
            this.interventionItems = await listStudentsByInterventionTemplateId({
                queryParams: {
                    page: 1,
                    interventionTemplateId
                },
                filterParams
            });
        },
        async loadInterventionTemplate() {
            // Route param is a number when the page is navigated to, but is a string when the page
            // is refreshed. Ensure it's a string:
            const interventionTemplateId = this.$route.params.id && String(this.$route.params.id);
            const interventionTemplate = await findInterventionTemplateById(interventionTemplateId);
            if (typeof interventionTemplate === 'object') {
                this.interventionTemplate = interventionTemplate;
            }
        },
        async loadContext() {
            // In our old Vue 2 set up, extra context about the intervention details was sent in
            // through route parameters when navigating to this page. These parameters were not
            // actually defined on the route's URL path, but Vue Router still allowed setting and
            // getting these params. The ability to set/get these "artificial params" has been
            // removed in Vue Router 4.

            // When we upgraded to Vue 3 and Vue Router 4, we needed a new mechanism to access
            // this extra context. This has been achieved by caching the related data right before
            // navigating to this page, and then when this page is loaded, that context is looked up
            // and if present, is used.

            // This is not a good pattern and is simply being done to limit the impact of the Vue 3
            // upgrade, and should probably not be repeated.

            // To do:
            // Instead of passing this data around, the underlying Node API call called in
            // `loadInterventionTemplate` could be edited to return the needed data. The idea of
            // passing data in came from before we had the Node API, and having this page
            // hit several Django endpoints and mapping them together was not performing well when
            // admins from large schools were hitting this page all at once. Now that we have that
            // Node API call, let's just get the data directly every time and avoid the complexity
            // of "get it sometimes". This would also be good because the extra context is lost
            // when refreshing the page - having it in the call would mean it's always available.
            const id = this.$route.params.id;
            const context = getters.getInterventionDetailsContext(id);

            // No matter the source, this.chatbotTemplate must be an object containing:
            // id (string), createReport (boolean), touchpoint (string), name (string)
            if (context && context.chatbotTemplate) {
                this.chatbotTemplate = context.chatbotTemplate;
            } else {
                const chatbotTemplates = await ChatbotTemplate.api.list({
                    intervention_template: id
                });
                this.chatbotTemplate = chatbotTemplates.results[0];
            }

            // No matter the source, this.chatbotFlow must be an object containing:
            // id (string), date (date string)
            if (context && context.chatbotFlow) {
                this.chatbotFlow = context.chatbotFlow;
            } else {
                if (this.chatbotTemplate) {
                    let chatbotFlows = await ChatbotFlow.api.list({
                        chatbot_template: this.chatbotTemplate.id
                    });
                    this.chatbotFlow = chatbotFlows.results[0];
                }
            }

            if (context && context.interventionIndex) {
                this.interventionIndex = context.interventionIndex;
            }
            if (context && context.interventionCount) {
                this.interventionCount = context.interventionCount;
            }
        },
        /**
         * Fetches email addresses of relevant students.
         *
         * If the number of students is small, emails will be copied to clipboard;
         * otherwise emails will be downloaded via CSV.
         *
         * @param {Array} students: If passed in, this array is the list of students used.
         *    If no array passed in, current parameters are used to find relevant students.
         *
         */
        async onEmail(students = null) {
            this.actionsLoading = true;
            try {
                // If individual students have been passed in:
                if (students) {
                    // If there aren't very many, copy emails to clipboard
                    if (students.length < Student.options.MAX_EMAILS_ON_CLIPBOARD) {
                        let emailsToCopy = students.map(student => student.email);
                        emailsToCopy = emailsToCopy.join(';');
                        copy(emailsToCopy);
                        this.$Alert.alert({
                            type: 'success',
                            message: '<h2>Emails copied to your clipboard!</h2>',
                            timeout: 3000
                        });
                        // Otherwise, download a CSV with student emails
                    } else {
                        const studentIds = students.map(student => student.id);
                        const response = await Student.api.downloadStudentData({
                            studentIds,
                            emailOnly: true
                        });
                        if (response.isBackground) {
                            this.updateBackgroundOperation(response.backgroundOp);
                        } else {
                            this.downloadFile(response.file);
                        }
                    }
                    return;
                }

                // If no students were passed in, use all students meeting the current parameters.
                // If there aren't very many, copy emails to clipboard
                if (this.totalStudentCount < Student.options.MAX_EMAILS_ON_CLIPBOARD) {
                    this.params.intervention = this.interventionTemplate.id;
                    const emailsFromServer = await Student.api.listEmails(this.params);
                    let emailsToCopy = emailsFromServer.map(({ email }) => email);
                    emailsToCopy = emailsToCopy.join(';');
                    copy(emailsToCopy);
                    this.$Alert.alert({
                        type: 'success',
                        message: '<h2>Emails copied to your clipboard!</h2>',
                        timeout: 3000
                    });
                    // Otherwise, download a CSV with student emails
                } else {
                    const response = await Student.api.downloadStudentData({
                        params: this.params,
                        emailOnly: true
                    });
                    if (response.isBackground) {
                        this.updateBackgroundOperation(response.backgroundOp);
                    } else {
                        this.downloadFile(response.file);
                    }
                }
            } finally {
                this.actionsLoading = false;
            }
        },
        /* Export a CSV of relevant students. */
        async onExport({ studentIds, tagCategories } = {}) {
            const params = Object.assign({}, this.params);
            params.intervention = this.interventionTemplate.id;
            params.tagIds = this.params.tags;
            this.actionsLoading = true;
            try {
                const response = await Student.api.downloadStudentData({
                    studentIds,
                    tagCategories,
                    params: params
                });

                // If the response we received is a background operation, add it to global state
                if (response.isBackground) {
                    this.updateBackgroundOperation(response.backgroundOp);
                } else {
                    this.downloadFile(response.file);
                }
            } finally {
                this.actionsLoading = false;
            }
        },
        /* Initiate a file download from the browser. */
        downloadFile(file) {
            // Create a link on the fly and programmatically click it to execute automatic download.
            // If a user can't download, it may be because their pop up blocker is blocking it.
            const url_dl = window.URL.createObjectURL(new Blob([file]));
            const link = document.createElement('a');
            link.href = url_dl;
            let file_name = `${moment().format()}_${this.user.schoolRef.name}`;
            link.setAttribute('download', file_name + '.csv');
            link.click();
        },
        async openModalCreateNote({ isAllSelected, students }) {
            let studentList = students;
            if (isAllSelected) {
                this.params.intervention = this.interventionTemplate.id;
                studentList = await Student.api.listEmails(this.params);

                // getting all students from server
            }
            this.$modal.show('modal-create-mass-note', {
                students: studentList
            });
        },
        async createMassNote(note) {
            try {
                await Note.api.createMassNote(note);
                this.$modal.hide('modal-create-mass-note');
                this.$Alert.alert({
                    type: 'success',
                    message: '<h2>Note Successfully Created!</h2>',
                    timeout: 3000
                });
            } catch (err) {
                this.$alert.alert({
                    type: 'error',
                    message: '<h2>Error creating Note</h2>',
                    timeout: 3000
                });
            }
        },
        async onOutreach({ action, isAllSelected, students }) {
            try {
                this.actionsLoading = true;
                let filterParams;

                if (isAllSelected) {
                    filterParams = this.formattedFilterParams;
                } else {
                    let interventionIds = [];
                    students.forEach(id => {
                        const intervention = this.interventionItems.students.find(
                            i => i.sid === id
                        );
                        if (intervention) {
                            interventionIds.push(intervention.interventionId);
                        }
                    });
                    filterParams = { interventionIds };
                }

                const response = await bulkMarkActionTaken({
                    queryParams: {
                        interventionTemplateId: String(this.interventionTemplate.id),
                        newOutreachStatus: action
                    },
                    filterParams,
                    errorHandlerOptions: {
                        rethrow: true,
                        enableAlert: true
                    }
                });

                if (
                    typeof response === 'object' &&
                    typeof response.updatedInterventionsMap === 'object' &&
                    typeof response.skippedInterventionsMap === 'object'
                ) {
                    // reload interventionTemplate to reflect changes in stats
                    await this.loadInterventionTemplate();

                    // update component data to reflect any changes for each student's intervention.
                    // interventions are updated or they are "skipped" if they are already approved by another admin.
                    // in either case, we populate any students in the UI with the latest data here:
                    this.interventionItems.students = this.interventionItems.students.map(
                        student => {
                            const updatedOrSkipped =
                                response.updatedInterventionsMap[student.interventionId] ||
                                response.skippedInterventionsMap[student.interventionId];
                            if (updatedOrSkipped) {
                                return {
                                    ...student,
                                    adminInitials: updatedOrSkipped.adminInitials,
                                    adminName: updatedOrSkipped.adminName,
                                    adminId: updatedOrSkipped.adminId,
                                    outreachStatus: updatedOrSkipped.outreachStatus
                                };
                            }

                            return student;
                        }
                    );

                    const updatedCount = Object.keys(response.updatedInterventionsMap).length;
                    const skippedCount = Object.keys(response.skippedInterventionsMap).length;

                    this.$Alert.alert({
                        type: 'success',
                        message: `Updated ${updatedCount} student${updatedCount === 1 ? '' : 's'}.${
                            skippedCount > 0
                                ? `<br/><br/>${skippedCount} student${
                                      skippedCount === 1 ? '' : 's'
                                  } ${
                                      skippedCount === 1 ? 'is' : 'are'
                                  } already marked Action Taken and ${
                                      skippedCount === 1 ? 'was' : 'were'
                                  } skipped.`
                                : ''
                        }`,
                        timeout: 10000
                    });
                }
            } finally {
                this.actionsLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/forms';
@import '../../styles/mixins/buttons';
@import '../../styles/mixins/lists';
@import '../../styles/mixins/text';

.row {
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    border: 0;
    padding: 1rem;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.clickable {
    cursor: pointer;
}

.arrow {
    transform: rotate(-180deg);
    margin-right: 1rem;
}

.back-link {
    font-size: 1.25rem;
    font-weight: bold;
    color: #000;
}

.intervention-details {
    padding: 20px 50px;
    height: 100%;
    max-width: unset;
    overflow-y: scroll;
}

.flow-name-row {
    padding-left: 2.25rem;
}

.flow-name {
    font-weight: bold;
}

.intervention-count {
    font-weight: normal;
    color: $accessible-gray;
}

.chatbot-template-download {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 3rem;
    color: $primary-brand-color;
}

.icon-download {
    stroke: $primary-brand-color;
    margin-left: 1rem;
}

.loading {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 26px;
}

.container {
    padding: 20px 50px;
    height: 100%;
    max-width: unset;
    overflow-y: scroll;
}

.analysis {
    margin: 0 auto;
    height: inherit;

    .analysis__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &-content {
        height: 100%;
    }

    .info-box {
        padding: 1rem calc(1rem + 75px) 1rem calc(1rem + 45px);
        margin-bottom: 1.5rem;
        line-height: 1.5;
        background-color: $white-blue;
        color: black;
        margin-top: 10px;
    }

    &__risk {
        &-level {
            &--row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
            }
        }
        &-icon {
            width: 2rem;
            height: 2rem;
            color: $gainsboro;
        }
        &-icon--large {
            width: 4rem;
            height: 4rem;
        }
        &-indicator {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: transparent;
            margin-right: 1rem;
            &--low {
                background-color: $dodger-blue;
            }
            &--high {
                background-color: $red;
            }
            &--medium {
                background-color: $yellow;
            }
        }
    }
}

.action-button {
    @include action-button();
    margin-left: 0.5rem;
}

.list {
    margin-top: 1rem;
    overflow: visible;
    padding-bottom: 10px;
}

.text-centered {
    @include text-centered();
}

.icon-HIGH_RISK {
    color: $red;
}

.icon-MEDIUM_RISK {
    color: $yellow;
}

.icon-hamburger {
    width: 28px;
    height: 28px;
    fill: $primary-brand-color;
}

.return-link {
    height: 2rem;
    width: 15rem;
    padding: 8px 12px 8px 7px;
    color: black;
    font-size: 16px;
    align-self: center;
    cursor: pointer;
}

.return-icon {
    transform: rotate(180deg);
    margin-right: 5px;
}

.v--modal-overlay.scrollable .v--modal-box {
    margin-bottom: 0;
}

.box-students {
    margin-top: 35px;

    &__btn-email {
        margin-right: 15px;
    }
}

.students-wrapper {
    display: flex;
}
@media only screen and (max-width: 768px) {
    .container {
        padding: 20px 20px;
        overflow: auto;
    }
    .list {
        overflow: auto;
        height: auto;
    }
}
:deep(.dropdown) {
    // manually setting the style for the dropdown here
    // width is set on the parent class

    .dropdown-input-container {
        width: 100%;
        align-items: center;
        border: 1px solid $secondary-gray;
        border-radius: 5px;

        > .search,
        .selected-items {
            height: 40px;
            border: none;
            background: transparent;

            height: 32px;

            min-width: unset;
            font-size: 14px;
        }
        &.disabled {
            border: 1px solid gray;
        }
    }
}
.filter-row {
    &__filter {
        display: flex;
        align-items: center;
        * {
            margin: 0 0.5rem;
        }
        label {
            white-space: nowrap;
            font-size: 14px;
            color: black;
            font-weight: bold;
        }
        .dd__school {
            width: 30rem;
            height: 40px;
            display: flex;
            align-items: center;
        }
    }
}
.toolbar {
    display: flex;
    width: 100%;
    margin: 0.5rem 0rem;
}
</style>

<style>
.v-select.single.unsearchable input[type='search'] {
    max-width: none;
    opacity: 1;
}
.v-select.unsearchable > .dropdown-toggle > .selected-tag + input[type='search'] {
    max-width: 1px;
}
.v-select .selected-tag {
    position: absolute;
    pointer-events: none;
}

.analysis .simplebar-scrollbar:before {
    background-color: #0a8bff;
    border-radius: 0px;
}

.v--modal-overlay.scrollable .v--modal-box {
    margin-bottom: 0;
}
</style>
