import axios from 'axios';
import { apiErrorHandler } from '@/services/api';
import * as Api from './index';

export const getKnowledgeAnalytics = (schoolId, sourceType, analyticsEnabled, tagIds) => {

    const defaultOptions = Api.getOptions();
    const queryParam = analyticsEnabled ? `?sourceType=${sourceType}` : '';
    const url = Api.getv2ApiBase(`/analytics/knowledge/school/${schoolId}${queryParam}`);
    const params = {};

    if (typeof tagIds === 'string') {
        params.tagIds = tagIds;
    }

    return axios
        .get(url, { ...defaultOptions, params })
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Get Knowledge Analytics'
            })
        );
};

export const getEngagementAnalytics = ({ schoolId, tagIds }) => {
    const url = Api.getv2ApiBase(`/analytics/engagement/school/${schoolId}`);
    const defaultOptions = Api.getOptions();
    const params = {};

    if (typeof tagIds === 'string') {
        params.tagIds = tagIds;
    }

    return axios
        .get(url, { ...defaultOptions, params })
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Get Engagement Analytics'
            })
        );
};
