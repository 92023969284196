<template>
    <div class="dashboard-container">
        <div class="main-container">
            <h1 v-if="isNewUser">Create SFTP User</h1>
            <h1 v-else>Add Additional SSH Keys</h1>
            <div class="label-input-container">
                <div class="label-container">
                    <p>School</p>
                    <p>Username</p>
                    <p>SSH Public Key</p>
                </div>
                <div class="input-container">
                    <v-select
                        :options="isNewUser ? schools : existingSftpUsers"
                        v-model="selectedSchool"
                        label="name"
                        @update:modelValue="schoolSelectionHandler"
                        placeholder="Search for a school..."
                        :clearable="false"
                        class="school-dropdown"
                    />
                    <input
                        class="input-box"
                        v-model="userName"
                        placeholder="edsightsuniversityoftechnology"
                        :disabled="!isNewUser"
                    />
                    <textarea
                        class="input-textarea"
                        v-model="sshKey"
                        placeholder="please paste the key exactly as is, no lines or spaces afterward"
                    ></textarea>
                </div>
            </div>
            <Button
                class="button-create"
                width="30rem"
                @click="getHandler"
                v-if="!showLoadingSpinner"
            >
                {{ isNewUser ? 'Create Sftp User' : 'Add SSH Key' }}
            </Button>
            <div v-else>
                <LoadingSpinner />
            </div>
            <ul class = 'list-container' v-if="displayLogs">
                <li 
                    v-for="log in logs"
                    :class="isNewUser
                    ? 'create-sftp-log'
                    : 'add-ssh-log'"
                >
                    {{ log }}
                </li>
            </ul>
        </div>
        <div class="instruction-container">
            <InputSwitch
                :modelValue="isNewUser"
                :label="isNewUser ? 'Switch to Add Ssh Key' : 'Switch to Create Sftp User'"
                @update:modelValue="toggleisNewUser"
            />
            <ol>
                <li>
                    Choose a school from the dropdown list.
                </li>
                <li v-if="isNewUser">
                    Username is populated automatically with no spaces/caps/special characters.
                    Only overwrite this if the username is missing characters.
                </li>
                <li>
                    Import SSH Key. Single line format is the preferred SSH key format. If 
                    there is a conversion problem, notify engineering to fix it.
                </li>
                <li v-if="isNewUser">
                    Switch to 'Add Ssh Key' if there was a problem during the import process.
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
import * as SchoolApi from '@/api/school';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/global/Button';
import InputSwitch from '@/components-deprecated/global/v2/InputSwitch';

export default {
    name: 'CreateUserOrAddSshKey',
    components: {
        LoadingSpinner,
        Button,
        InputSwitch
    },
    data() {
        return {
            schoolId: '',
            userName: '',
            sshKey: '',
            schools: [],
            displayLogs: false,
            logs: [],
            showLoadingSpinner: false,
            selectedSchool: '',
            existingSftpUsers: [],
            // using the inputswitch component to switch between functions
            // isNewUser = true for create sftp user, false for add ssh key
            isNewUser: true
        };
    },
    created() {
        this.loadSftpSchoolList();
    },
    methods: {
        async loadSftpSchoolList() {
            const [schools, existingSftpUsers] = await Promise.all([
                SchoolApi.listSchoolsWithoutStorageBucket(),
                SchoolApi.listSchoolsWithStorageBuckets()
            ]);

            this.schools = schools;
            this.existingSftpUsers = existingSftpUsers;
        },
        formatString(inputString) {
            const formattedString = inputString.replace(/[\s\p{P}\d]/gu, '').toLowerCase();
            return formattedString;
        },
        schoolSelectionHandler(value) {
            this.selectedSchool = value;
            this.schoolId = value.id;
            if (this.isNewUser) {
                this.userName = this.formatString(value.name);
            } else {
                this.userName = value.username;
            }
        },
        resetForm() {
            this.selectedSchool = '';
            this.schoolId = '';
            this.sshKey = '';
            this.userName = '';
        },
        resetLogs() {
            this.displayLogs = false;
            this.logs = [];
        },
        async createSftpUserHandler() {
            this.showLoadingSpinner = true;
            this.resetLogs();

            try {
                const payload = {
                    schoolId: this.schoolId,
                    userName: this.userName,
                    sshKey: this.sshKey
                };

                const response = await SchoolApi.createSftpUser({ payload });
                if (response) {
                    const { logs, success } = response;

                    this.displayLogs = true;
                    this.logs = logs;

                    this.$Alert.alert({
                        type: success === true ? 'success' : 'error',
                        message: success === true ? 'SFTP User Created!' : logs[logs.length - 1],
                        timeout: 3000
                    });

                    this.resetForm();
                    this.loadSftpSchoolList();
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.showLoadingSpinner = false;
            }
        },
        async addSshKeyHandler() {
            this.showLoadingSpinner = true;
            this.resetLogs();
            try {
                const payload = {
                    schoolId: this.schoolId,
                    userName: this.userName,
                    sshKey: this.sshKey
                };

                const response = await SchoolApi.addSshKeyToSftpUser({ payload });
                if (response) {
                    const message = response.message;

                    this.displayLogs = true;
                    this.logs = [message];

                    this.$Alert.alert({
                        type: 'success',
                        message,
                        timeout: 3000
                    });

                    this.resetForm();
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.showLoadingSpinner = false;
            }
        },
        toggleisNewUser() {
            this.resetForm();
            this.resetLogs();
            this.isNewUser = !this.isNewUser;
        },
        getHandler() {
            return this.isNewUser ? this.createSftpUserHandler() : this.addSshKeyHandler();
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.tab-content-container) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-container {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr 1fr;
    align-items: start;
    justify-items: center;
    width: 100%;
    margin: 0 auto 0 10%;
    // gap: 20px;
}
.main-container {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.instruction-container {
    grid-column: 3 / 4;
    width: 30rem;
}
.label-input-container {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: center;
    margin-right: 13.5rem;
}
.label-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 5px;
}
.input-container {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 25rem;
}
.input-box {
    height: 27px;
    width: 30rem;
    padding-left: 10px;
    border: 1px solid #3c3c3c42;
    border-radius: 3px;
}
.input-textarea {
    height: 10rem;
    width: 30rem;
    border-radius: 3px;
    border-color: #3c3c3c42;
}
.button-create {
    margin-left: 8px;
    margin-bottom: 1rem;
}
.school-dropdown {
    width: 30rem;
}
.instruction-container {
    width: 30rem;
    transform: translate(-5rem, 5rem);
}
.create-sftp-log {
    margin-left: 10rem;
}
/* Override the green background of the InputSwitch slider */
::v-deep .input-switch-container.default .p-inputswitch.p-highlight .p-inputswitch-slider {
        background: #cbd5e1 !important;
}
</style>
