<script setup>
import { computed, ref, defineProps, defineEmits, onMounted } from 'vue';
import { BaseDrawer, BaseChatMessageList, BaseButton } from '@edsights/ui-core';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import v2CollectionManager from '@/services/v2CollectionManager';

const props = defineProps({
    studentId: {
        type: String,
        required: true
    },
    scrollToInterventionTemplateId: {
        type: Number,
        default: null
    }
});

const emit = defineEmits(['close']);

const messagesCollection = ref(
    v2CollectionManager.create({
        listType: 'studentConversation',
        paginationOptions: {
            size: 25
        },
        errorHandlerOptions: {
            enableAlert: true,
            overrideMessage:
                "There was a problem loading the student's conversation. Please try again.",
            rethrow: false
        }
    })
);

const scrollToMessageId = ref(null);
const searchingForMessageId = ref(false);

const formattedMessages = computed(() => {
    return messagesCollection.value.list.map(message => ({
        id: message.id,
        text: message.text,
        date: new Date(message.datetimeCreated),
        userId: message.messageType === 'bot' ? 'bot' : props.studentId,
        userName: message.messageType === 'bot' ? botName.value : studentName.value
    }));
});

const studentName = computed(() => {
    return messagesCollection.value.listInfo.studentName || '';
});

const botName = computed(() => {
    return messagesCollection.value.listInfo.botName || '';
});

const messagesCollectionLoading = computed(() => {
    return messagesCollection.value.refreshing;
});

const messageCollectionLoadingNextPage = computed(() => {
    return messagesCollection.value.loadingNextPage;
});

const messageCollectionHasNextPage = computed(() => {
    return messagesCollection.value.pagination.hasNextPage;
});

const showMainLoadingSpinner = computed(() => {
    return messagesCollectionLoading.value || searchingForMessageId.value;
});

const getPageSize = () => {
    let pageSize = 25;

    // When scrolling to an intervention we increase the page size
    // to increase the likelihood of finding the message in fewer requests.
    // Nearly all students have fewer than 250 messages.
    if (props.scrollToInterventionTemplateId) {
        pageSize = 250;
    }

    return pageSize;
};

const loadInitialMessages = async () => {
    messagesCollection.value.filters.studentId = props.studentId;
    messagesCollection.value.paginationOptions.size = getPageSize();
    // Pagination works with limit/offset, so filter by older than
    // now so the offset doesn't shift as new messages come in while
    // the user is scrolling through pages of messages.
    // Closing/re-opening the drawer will re-set this time and get newer messages.
    messagesCollection.value.filters.beforeDate = new Date().toISOString();
    await messagesCollection.value.refresh();
};

// Since we don't have true cursor-based pagination
// and since the vast majority of students have at most a couple of hundred messages,
// here we programmatically load one page at a time until we
// find the desired message.
// Note: The getPageSize function returns a larger page size (currently 250)
//.      in this scenario so we can find the message in fewer calls.
// Note: The mean conversation length is ~50 messages. 99.9% of
//       students have ~200 messages or fewer (~3.3 standard deviations from mean).
//       So, we'll generally be making only the initial 1st page load and no more.
const scrollToInterventionTemplateId = async () => {
    // This maxMessages represents an extreme outlier - it covers the longest convo length seen
    // for a single student.
    const maxMessagesToScan = 10000;
    const maxPageLoads = Math.ceil(maxMessagesToScan / getPageSize());

    // Initiated at 1 to account for the initial page load before scanning
    let pageLoads = 1;
    if (props.scrollToInterventionTemplateId) {
        searchingForMessageId.value = true;
        let messageFound = messagesCollection.value.list.find(
            message =>
                message.relationships.interventionTemplateId ===
                props.scrollToInterventionTemplateId
        );
        while (messageCollectionHasNextPage.value && !messageFound && pageLoads < maxPageLoads) {
            await loadOlderMessages();
            messageFound = messagesCollection.value.list.find(
                message =>
                    message.relationships.interventionTemplateId ===
                    props.scrollToInterventionTemplateId
            );
            pageLoads++;
        }

        if (messageFound) {
            scrollToMessageId.value = messageFound.id;
        } else {
            console.error(
                'Message not found for intervention template id: ',
                props.scrollToInterventionTemplateId
            );
        }

        searchingForMessageId.value = false;
    }
};

const loadOlderMessages = async () => {
    if (messageCollectionHasNextPage.value && !messageCollectionLoadingNextPage.value) {
        await messagesCollection.value.addNextPage();
    }
};

const close = () => {
    emit('close');
};

onMounted(async () => {
    await loadInitialMessages();

    if (props.scrollToInterventionTemplateId) {
        await scrollToInterventionTemplateId();
    }
});
</script>

<template>
    <BaseDrawer
        class="student-chat-history-drawer"
        :header="`Chat History${studentName ? ` for ${studentName}` : ''}`"
        width="430px"
        @close="close"
    >
        <div class="drawer-content">
            <LoadingSpinner v-if="showMainLoadingSpinner" />
            <BaseChatMessageList
                v-else
                class="message-list"
                :outgoingUserId="studentId"
                :messages="formattedMessages"
                :scrollToMessageId="scrollToMessageId"
                @scrolledToTop="loadOlderMessages"
            >
                <template #before-messages>
                    <p
                        v-if="
                            !messagesCollectionLoading &&
                                !messageCollectionLoadingNextPage &&
                                !messageCollectionHasNextPage
                        "
                        class="history-statement"
                    >
                        History is only visible since August 1, 2024. For questions on previous
                        history, reach out to your EdSights point of contact.
                    </p>
                    <LoadingSpinner v-if="messageCollectionLoadingNextPage" />
                </template>
            </BaseChatMessageList>
        </div>

        <template #footer>
            <div class="drawer-footer">
                <BaseButton :style="'secondary'" label="Close" @click="close" />
            </div>
        </template>
    </BaseDrawer>
</template>

<style scoped lang="scss">
.student-chat-history-drawer {
    .drawer-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        .message-list {
            width: 100%;
            height: 100%;
            padding-top: 20px;

            .history-statement {
                font-size: 0.83rem;
                text-align: center;
                padding-bottom: 20px;
            }
        }
    }

    .drawer-footer {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
