<template>
    <div class="flows">
        <div class="toolbar">
            <div class="school-filter">
                <SchoolDropDown @selected-school-id-updated="filterBySchool" />
            </div>
        </div>
        <div class="description">
            <h2>Flows</h2>
            <p>
                These are all the flows drafted and scheduled. You can filter by school, name,
                status, or label.
            </p>
        </div>

        <div class="box">
            <BoxContainer title="ALL FLOWS" hideSplitter>
                <LoadingSpinner v-if="loading" />
                <FlowsTable
                    v-if="!loading"
                    :columns="tableColumns"
                    :data="tableData"
                    :schoolFilter="schoolFilter"
                    :labels="labels"
                    @cancelFlow="openCancelFlowModal"
                    @createNew="goToCreateNew"
                    @flowDateUpdated="onFlowDateUpdated"
                    @flowScheduled="onFlowScheduled"
                    @onFilter="onFilter"
                />
            </BoxContainer>
            <Paginator
                v-if="!loading && flowsCollection.pagination.next"
                :loading="flowsCollection.loadingNextPage"
                @next-page="flowsCollection.addNextPage()"
            />
        </div>
        <CancelFlowModal
            v-if="cancelFlowModalData.showModal"
            :flow="cancelFlowModalData.flow"
            @onClose="closeCancelFlowModal"
            @onSavedAsDraft="onSavedAsDraft"
            @onSavedAsCancelled="onSavedAsCancelled"
        />
    </div>
</template>

<script>
import BoxContainer from '@/components-deprecated/BoxContainer';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import SchoolDropDown from '@/components-deprecated/inputs/SchoolDropDown';
import FlowsTable from '@/views/staff/ChatbotFlowList/components/FlowsTable';
import { CHATBOT_FLOW_STATUS } from '@/consts/chatbot-flow';
import { getChatbotFlowLabels } from '@/api/chatbot-flow-labels';
import CancelFlowModal from '@/views/staff/ChatbotFlowList/components/CancelFlowModal';
import v2CollectionManager from '@/services/v2CollectionManager';
import Paginator from '@/components-deprecated/Paginator';

export default {
    name: 'Flows',
    components: {
        FlowsTable,
        SchoolDropDown,
        BoxContainer,
        LoadingSpinner,
        CancelFlowModal,
        Paginator
    },
    data() {
        return {
            flows: [],
            flowsCollection: v2CollectionManager.create({
                listType: 'chatbotFlows',
                paginationOptions: {
                    size: 50
                },
                filters: {
                    statuses: [
                        CHATBOT_FLOW_STATUS.DRAFT.value,
                        CHATBOT_FLOW_STATUS.SCHEDULED.value,
                        CHATBOT_FLOW_STATUS.INITIATED.value
                    ],
                    labels: null,
                    schoolId: null,
                    name: null
                },
                errorHandlerOptions: {
                    enableAlert: false,
                    rethrow: true
                }
            }),
            loading: true,
            selectedWorkflow: null,
            downloadingFlows: [],
            CHATBOT_FLOW_STATUS: CHATBOT_FLOW_STATUS,
            schoolFilter: '',
            labels: [],
            cancelFlowModalDataDefaults: {
                showModal: false,
                flow: null
            },
            cancelFlowModalData: { ...this.cancelFlowModalDataDefaults }
        };
    },
    computed: {
        tableColumns() {
            return [
                { dataKey: 'name', label: 'Template Name' },
                { dataKey: 'labels', label: 'Labels' },
                { dataKey: 'tags', label: 'Tags' },
                { dataKey: 'status', label: 'Status' },
                { dataKey: 'actions', label: 'Actions' }
            ];
        },
        tableData() {
            return this.flowsCollection.list.map(flow => ({
                id: flow.id,
                name: flow.templateRef.name,
                date: flow.date,
                labels: flow.labels,
                tags: flow.tags,
                status: flow.status,
                historical: flow.historical,
                touchpointRef: flow.touchpointRef,
                schoolsRef: flow.schoolsRef
            }));
        }
    },
    async created() {
        try {
            this.loading = true;
            await this.loadFlows();
            this.labels = await getChatbotFlowLabels();
        } catch (error) {
            this.$Alert.alert({
                type: 'error',
                message: 'There was a problem loading the flows. Please try again.'
            });
        } finally {
            this.loading = false;
        }
    },
    methods: {
        async loadFlows() {
            await this.flowsCollection.refresh();
        },
        goToCreateNew() {
            this.$router.push({
                name: 'ChatbotFlowBuilderCreate'
            });
        },
        openCancelFlowModal(flowId) {
            if (typeof flowId === 'string' && Array.isArray(this.flowsCollection.list)) {
                const flow = this.flowsCollection.list.find(f => f.id === flowId);
                if (typeof flow === 'object') {
                    this.cancelFlowModalData = {
                        ...this.cancelFlowModalDataDefaults,
                        showModal: true,
                        flow
                    };
                }
            }
        },
        async closeCancelFlowModal() {
            this.cancelFlowModalData = this.cancelFlowModalDataDefaults;
        },
        onSavedAsDraft(flowId) {
            if (typeof flowId === 'string' && Array.isArray(this.flowsCollection.list)) {
                // close modal and update component data to reflect change
                this.closeCancelFlowModal();
                this.flowsCollection.list = this.flowsCollection.list.map(flow => {
                    if (flow.id === flowId) {
                        return {
                            ...flow,
                            status: CHATBOT_FLOW_STATUS.DRAFT.value
                        };
                    }

                    return flow;
                });
            }
        },
        onSavedAsCancelled(flowId) {
            if (typeof flowId === 'string' && Array.isArray(this.flowsCollection.list)) {
                // close modal and update component data to reflect change
                this.closeCancelFlowModal();
                this.flowsCollection.list = this.flowsCollection.list.filter(flow => flow.id !== flowId);
            }
        },
        async filterBySchool(schoolId) {
            this.schoolFilter = schoolId;
            this.flowsCollection.filters.schoolId = this.schoolFilter;
            await this.loadFlows();
        },
        onFlowDateUpdated(flow) {
            if (
                typeof flow === 'object' &&
                typeof flow.id === 'string' &&
                typeof flow.date === 'string'
            ) {
                const { id, date } = flow;
                this.flowsCollection.list = this.flowsCollection.list.map(flow => {
                    if (flow.id === id) {
                        return {
                            ...flow,
                            date
                        };
                    }

                    return flow;
                });
            }
        },
        onFlowScheduled(scheduledFlow) {
            if (typeof scheduledFlow === 'object' && typeof scheduledFlow.id === 'string') {
                this.flowsCollection.list = this.flowsCollection.list.map(flow => {
                    if (flow.id === scheduledFlow.id) {
                        return {
                            ...flow,
                            status: CHATBOT_FLOW_STATUS.SCHEDULED.value
                        };
                    }

                    return flow;
                });
            }
        },
        async onFilter(filters) {
            const { labels, status, name } = filters;
            this.flowsCollection.filters.name = name;
            this.flowsCollection.filters.labels = labels;
            this.flowsCollection.filters.statuses = status || [
                CHATBOT_FLOW_STATUS.DRAFT.value,
                CHATBOT_FLOW_STATUS.SCHEDULED.value,
                CHATBOT_FLOW_STATUS.INITIATED.value
            ];
            await this.loadFlows();
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.flows {
    min-height: 100vh;
    .description {
        margin-bottom: 10px;
    }
    .box {
        min-height: 500px;
    }
}
</style>
