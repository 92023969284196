import axios from 'axios';
import { apiErrorHandler, v2ApiErrorHandler } from '../services/api';
import * as Api from './index';

// retrieves all integrations
export const list = async ({ integrationOrigin, page, page_size, errorHandlerOptions = {} }) => {
    try {
        const options = Api.getOptions();
        const url = await Api.getIntegrationBase(`/integration`);
        const params = {
            page,
            page_size
        };

        if (typeof integrationOrigin === 'string') {
            params.integrationOrigin = integrationOrigin;
        }

        const response = await axios.get(url, { ...options, params });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem loading the integration list. Please try again.',
            ...errorHandlerOptions
        })
    }
};

// retrieves an integration
export const getIntegrationById = async integrationId => {
    const url = await Api.getIntegrationBase(`/integration/${integrationId}`);
    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Get Integration by id error'
            })
        );
};

// creates an integration
export const createIntegration = async integration => {
    const url = await Api.getIntegrationBase('/integration');
    const payload = {
        title: integration.integrationTitle,
        integrationTypeId: integration.integrationType.id,
        integrationStatusId: integration.integrationStatusId,
        schoolId: integration.integrationSchool.id,
        createdById: integration.integrationCreatedBy,
        deactivationReason: integration.integrationDeactivationReason
    };

    return axios
        .post(url, payload, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Create Integration error'
            })
        );
};

export const uploadToSignedUrl = (signedUrl, file, userid, typename) => {
    // old AWS way
    if (!userid) {
        // the edsights version header breaks signed url uploads
        const config = {
            transformRequest: [
                (data, headers) => {
                    delete headers.common['X-Edsights-Version'];
                    return data;
                }
            ]
        };
        return axios.put(signedUrl, file, config).catch(
            apiErrorHandler({
                apiName: 'Upload Signed Url Integration error'
            })
        );
    }

    // GCS way
    return axios
        .put(signedUrl, file, {
            headers: {
                'x-goog-meta-userid': userid,
                'x-goog-meta-typename': typename
            }
        })
        .catch(
            apiErrorHandler({
                apiName: 'Upload Signed Url Integration error'
            })
        );
};

export const getCsvUrl = async integrationId => {
    const url = await Api.getIntegrationBase(`/integration/${integrationId}/get-csv-url`);
    return axios.get(url, Api.getOptions()).catch(
        apiErrorHandler({
            apiName: 'Get csv url failed.'
        })
    );
};

export const getCsv = (signedCsvUrl, integrationTitle) => {
    return axios({
        url: signedCsvUrl,
        method: 'GET',
        responseType: 'blob'
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${integrationTitle}.csv`); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(
            apiErrorHandler({
                apiName: 'Download csv failed.'
            })
        );
};

export const setInvalidStatus = async integrationId => {
    const url = await Api.getIntegrationBase(`/integration/${integrationId}/status/invalid`);
    return axios
        .patch(url, {}, Api.getOptions())
        .catch(apiErrorHandler({ apiName: 'Patch integration status error' }));
};
