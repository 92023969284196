<template>
    <div class="flows-table">
        <div class="chips-wrapper" v-if="filterChips.length">
            <Chips :chips="filterChips" @chip-clicked="removeFilterChip" showCloseIcon />
        </div>
        <div class="flows-toolbar">
            <div class="filters">
                <TextInput
                    class="search"
                    placeholder="Search Flows"
                    iconType="search"
                    v-model="searchValue"
                    @input="handleInput"
                />
                <Dropdown
                    class="status-filter"
                    label="Filter by Status"
                    :options="statusFilterOptions"
                    :activeOptions="[filters.status]"
                    @option-clicked="toggleStatusFilter"
                />
                <Dropdown
                    class="label-filter"
                    label="Filter by Label"
                    :options="labelFilterOptions"
                    :activeOptions="filters.labels"
                    @option-clicked="toggleLabelFilter"
                    enableSearch
                />
            </div>
            <div class="buttons">
                <Button @click="$emit('createNew')">
                    New
                </Button>
            </div>
        </div>

        <Table
            :columns="columns"
            :data="data"
            class="flows"
        >
            <template v-slot:rows="{ rows }">
                <template v-for="row in rows" :key="`row-${row.id}`">
                    <tr class="row">
                        <td class="name">
                            <div class="content">
                                <img
                                    v-if="row.touchpointRef.isCustom"
                                    class="icon"
                                    alt="Feather representing a custom touchpoint"
                                    src="@/assets/icons/feather.png"
                                />
                                <img
                                    v-else
                                    class="icon"
                                    src="@/assets/icons/edsights-e.png"
                                    alt="EdSights logo representing a non-custom touchpoint"
                                />
                                <div>
                                    <div class="name-container">
                                        <h3
                                            @click="toggleSelectedRow(row.id)"
                                            title="Click to view details."
                                        >
                                            {{ row.name }}
                                        </h3>
                                        <div class="tooltip-container">
                                            <img
                                                v-tooltip="row.touchpointRef.description"
                                                class="tooltip-image"
                                                src="@/assets/icons/question-mark.svg"
                                                alt="Question mark"
                                                tabindex="0"
                                            />
                                        </div>
                                        <CreateReusableTemplateFromChatbotFlowButton
                                            :chatbotFlowId="row.id"
                                        />
                                    </div>
                                    <FlowDate
                                        :date="row.date"
                                        :flowId="row.id"
                                        :flowStatus="row.status"
                                        @flowDateUpdated="onFlowDateUpdated"
                                    />
                                </div>
                            </div>
                        </td>
                        <td class="labels">
                            <div class="content">
                                <Chips :chips="getRowLabelChips(row)" />
                            </div>
                        </td>
                        <td class="tags">
                            <div class="content">
                                <Chips :chips="getRowTagChips(row)" />
                            </div>
                        </td>
                        <td class="status">
                            <div
                                @dblclick="toggleStatus(row)"
                                class="content"
                                v-if="
                                    row.status === CHATBOT_FLOW_STATUS.DRAFT.value ||
                                        row.status === CHATBOT_FLOW_STATUS.SCHEDULED.value
                                "
                            >
                                <div
                                    class="status-indicator"
                                    :class="[CHATBOT_FLOW_STATUS[row.status].value]"
                                />
                                {{ CHATBOT_FLOW_STATUS[row.status].label }}
                            </div>
                        </td>
                        <td class="actions">
                            <div class="content">
                                <div class="edit-cancel-container" v-if="row.status === CHATBOT_FLOW_STATUS.SCHEDULED.value">
                                    <a
                                        :href="`/chatbot-flow/${row.id}`"
                                        class="btn-transparent"
                                        :class="{ disabled: row.historical }"
                                        target="_blank"
                                        :aria-disabled="row.historical ? 'true' : 'false'"
                                    >
                                        <h3>EDIT</h3>
                                    </a>
                                    <Button
                                        styleType="off"
                                        unbordered
                                        class="btn-transparent"
                                        @click.stop="$emit('cancelFlow', row.id)"
                                    >
                                        <h3>CANCEL</h3>
                                    </Button>
                                </div>
                                <a
                                    v-if="row.status === CHATBOT_FLOW_STATUS.INITIATED.value"
                                    :href="`/chatbot-flow/${row.id}`"
                                    class="btn-transparent"
                                    :class="{ disabled: row.historical }"
                                    target="_blank"
                                    :aria-disabled="row.historical ? 'true' : 'false'"
                                >
                                    <h3>VIEW</h3>
                                </a>
                                <div class="edit-cancel-container" v-if="row.status === CHATBOT_FLOW_STATUS.DRAFT.value">
                                    <a
                                        :href="`/chatbot-flow/${row.id}`"
                                        class="btn-transparent"
                                        :class="{ disabled: row.historical }"
                                        target="_blank"
                                        :aria-disabled="row.historical ? 'true' : 'false'"
                                    >
                                        <h3>EDIT</h3>
                                    </a>
                                    <Button
                                        styleType="off"
                                        unbordered
                                        class="btn-transparent"
                                        @click.stop="$emit('cancelFlow', row.id)"
                                    >
                                        <h3>CANCEL</h3>
                                    </Button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr
                        class="expanded-row"
                        v-if="selectedRowId === row.id"
                        :key="`expanded-row-${row.id}`"
                    >
                        <td class="expanded-row-cell" colspan="4">
                            <FlowSchoolInfo :flowId="row.id" />
                        </td>
                    </tr>
                </template>
            </template>
        </Table>
        <EmptyState
            v-if="data.length && data.length === 0"
            message="No results."
            sub-message="Try another search."
        />
        <EmptyState
            v-if="!data.length"
            message="No results."
            sub-message="There are no flows currently in the system."
        />
        <ScheduleFlowModal
            v-if="scheduleFlowModalData.showModal"
            :chatbotFlowId="scheduleFlowModalData.chatbotFlow.id"
            :saving="scheduleFlowModalData.saving"
            @onClose="closeScheduleFlowModal"
            @onSave="scheduleChatbotFlow"
        />
    </div>
</template>

<script>
import Table from '@/components-deprecated/global/Table';
import Button from '@/components-deprecated/Button';
import { CHATBOT_FLOW_STATUS, DRAFT_NULL_DATE_PLACEHOLDER } from '@/consts/chatbot-flow';
import FlowSchoolInfo from '@/views/staff/ChatbotFlowList/components/FlowSchoolInfo';
import TextInput from '@/components-deprecated/global/TextInput';
import Dropdown from '@/components-deprecated/global/Dropdown';
import Chips from '@/components-deprecated/global/Chips';
import EmptyState from '@/components-deprecated/global/EmptyState';
import FlowDate from '@/views/staff/ChatbotFlowList/components/FlowDate';
import ScheduleFlowModal from '@/views/staff/ChatbotFlowList/components/ScheduleFlowModal';
import { findById, updateChatbotFlow } from '@/api/chatbot-flow';
import { strictEqual } from 'assert';
import CreateReusableTemplateFromChatbotFlowButton from '@/views/staff/ChatbotFlowList/components/CreateReusableTemplateFromChatbotFlowButton';
import { debounce } from 'lodash';

export default {
    name: 'FlowsTable',
    components: {
        FlowDate,
        EmptyState,
        Chips,
        TextInput,
        FlowSchoolInfo,
        Table,
        Button,
        Dropdown,
        ScheduleFlowModal,
        CreateReusableTemplateFromChatbotFlowButton
    },
    props: {
        columns: {
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: true
        },
        schoolFilter: {
            type: String,
            required: false,
            default: ''
        },
        labels: {
            type: Array,
            required: true,
            validator: function(labels) {
                return (
                    Array.isArray(labels) &&
                    labels.every(l => typeof l.text === 'string' && typeof l.id === 'string')
                );
            }
        }
    },
    data() {
        return {
            CHATBOT_FLOW_STATUS: CHATBOT_FLOW_STATUS,
            selectedRowId: null,
            filters: {
                name: '',
                status: null,
                labels: []
            },
            statusFilterOptions: [
                CHATBOT_FLOW_STATUS.SCHEDULED,
                CHATBOT_FLOW_STATUS.INITIATED,
                CHATBOT_FLOW_STATUS.DRAFT
            ],
            scheduleFlowModalDataDefaults: {
                showModal: false,
                chatbotFlow: null,
                saving: false
            },
            scheduleFlowModalData: { ...this.scheduleFlowModalDataDefaults },
            searchValue: ''
        };
    },
    created() {
        this.debouncedOnSearch = debounce(this.onSearch, 300);
    },
    methods: {
        toggleSelectedRow(rowId) {
            if (this.selectedRowId === rowId) {
                this.selectedRowId = null;
            } else {
                this.selectedRowId = rowId;
            }
        },
        removeFilterChip(chip) {
            const { filterType, value } = chip;
            if (filterType === 'status') {
                this.toggleStatusFilter(value);
            }

            if (filterType === 'label') {
                this.toggleLabelFilter(value);
            }
            this.$emit('onFilter', this.filters);
        },
        toggleStatusFilter(status) {
            if (this.filters.status === status) {
                this.filters.status = null;
            } else {
                this.filters.status = status;
            }
            this.$emit('onFilter', this.filters);
        },
        toggleLabelFilter(labelId) {
            if (this.filters.labels.includes(labelId)) {
                this.filters.labels = this.filters.labels.filter(l => l !== labelId);
            } else {
                this.filters.labels.push(labelId);
            }
            this.$emit('onFilter', this.filters);
        },
        getRowLabelChips(row) {
            if (typeof row === 'object' && Array.isArray(row.labels)) {

                const labels = row.labels.reduce((acc, label) => {
                    const { text, id } = label;
                    if (typeof text === 'string' && typeof id === 'string') {
                        acc.push({ label: text, value: id });
                    }
                    return acc;
                }, []);
            
                // If there are more than 3 labels, consolidate them
                if (labels.length > 3) {
                    const firstThreeLabels = labels.slice(0, 3);
                    const remainingLabels = labels.slice(3);
                    
                    const consolidatedLabelDisplayChip = '...';
                    const consolidatedTooltip = remainingLabels.map(obj => obj.label).join(', ');
                    
                    const consolidatedLabel = { 
                        label: consolidatedLabelDisplayChip, 
                        value: 'more',
                        tooltip: consolidatedTooltip
                    };
                    
                    return [...firstThreeLabels, consolidatedLabel];
                }
            
                // If 3 or fewer labels, return them as is
                return labels;
            }
        
            return [];
        },
        getRowTagChips(row) {
            if (typeof row === 'object' && Array.isArray(row.tags)) {

                const tags = row.tags.reduce((acc, tag) => {
                    const { text, id } = tag;
                    if (typeof text === 'string' && typeof id === 'number') {
                        acc.push({ label: text, value: id });
                    }
                    return acc;
                }, []);
            
                // If there are more than 3 tags, consolidate them
                if (tags.length > 3) {
                    const firstThreeTags = tags.slice(0, 3);
                    const remainingTags = tags.slice(3);
                    
                    const consolidatedTagDisplayChip = '...';
                    const consolidatedTooltip = remainingTags.map(obj => obj.label).join(', ');
                    
                    const consolidatedTag = { 
                        label: consolidatedTagDisplayChip, 
                        value: 'more',
                        tooltip: consolidatedTooltip
                    };
                    
                    return [...firstThreeTags, consolidatedTag];
                }
            
                // If 3 or fewer tags, return them as is
                return tags;
            }
        
            return [];
        },
        onFlowDateUpdated(flow) {
            this.$emit('flowDateUpdated', flow);
        },
        toggleStatus(flow) {
            if (
                !flow.date ||
                new Date(flow.date) < Date.now() ||
                flow.date === DRAFT_NULL_DATE_PLACEHOLDER
            ) {
                this.$Alert.alert({
                    type: 'error',
                    message: '<h2>Flow requires a valid date to be scheduled.</h2>',
                    timeout: 3000
                });
            } else {
                this.scheduleFlowModalData = {
                    ...this.scheduleFlowModalDataDefaults,
                    showModal: true,
                    chatbotFlow: flow
                };
            }
        },
        closeScheduleFlowModal() {
            this.scheduleFlowModalData = { ...this.scheduleFlowModalDataDefaults };
        },
        async scheduleChatbotFlow(studentCount) {
            try {
                strictEqual(typeof studentCount, 'number');

                this.scheduleFlowModalData.saving = true;

                // refetch flow in case it was already scheduled
                const flow = await findById({
                    id: this.scheduleFlowModalData.chatbotFlow.id,
                    includeFull: false,
                    errorHandlerOptions: { rethrow: false, enableAlert: true }
                });

                if (flow._Status === 'SCHEDULED') {
                    this.$Alert.alert({
                        type: 'error',
                        message: 'Flow has already been scheduled.',
                        timeout: 3000
                    });

                    this.closeScheduleFlowModal();

                    return;
                }

                await updateChatbotFlow(
                    flow.id,
                    {
                        status: CHATBOT_FLOW_STATUS.SCHEDULED.value
                    },
                    {
                        rethrow: true,
                        // enableAlert: true makes the backend's validation appear in an alert.
                        // this should catch any invalid template bindings
                        // (which are the only thing that could be invalid in this context).
                        enableAlert: true,
                        messagePrefix: 'There was a problem scheduling the flow:'
                    }
                );

                this.$Alert.alert({
                    type: 'success',
                    message: '<h2>Chatbot flow scheduled!</h2>',
                    timeout: 10000
                });

                this.$emit('flowScheduled', flow);
                this.closeScheduleFlowModal();
            } catch (error) {
                this.closeScheduleFlowModal();
            }
        },
        handleInput(event) {
            const value = event.target.value;
            this.searchValue = value;
            this.debouncedOnSearch(value);
        },
        onSearch() {
            this.filters.name = this.searchValue;
            this.$emit('onFilter', this.filters);
        },
    },
    computed: {
        filterChips() {
            const chips = [];

            if (this.filters.status) {
                const { label, value } = CHATBOT_FLOW_STATUS[this.filters.status];
                chips.push({ label: `Status: ${label}`, value, filterType: 'status' });
            }

            this.filters.labels.forEach(labelId => {
                const label = this.labels.find(l => l.id === labelId);
                const { id, text } = label;
                chips.push({ label: `Label: ${text}`, value: id, filterType: 'label' });
            });

            return chips;
        },
        labelFilterOptions() {
            if (this.labels && Array.isArray(this.labels)) {
                return this.labels.map(l => ({ value: l.id, label: l.text }));
            }
            return [];
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.flows-table {
    .chips-wrapper {
        .chips-container {
            margin-bottom: 15px;
        }
    }

    .flows-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 25px;

        .filters {
            display: flex;
            align-items: center;

            .search {
                width: 27rem;
            }

            .status-filter,
            .label-filter {
                width: 200px;
                margin-left: 20px;
            }
        }

        .buttons {
            button {
                margin-left: 1rem;
            }
        }
    }
    &:deep(.table-container table) {
        border-collapse: collapse;

        th,
        td {
            &:nth-of-type(1) {
                width: 35%;
            }

            &:nth-of-type(2) {
                width: 25%;
            }

            &:nth-of-type(3) {
                width: 20%;
            }

            &:nth-of-type(4) {
                width: 10%;
            }

            &:nth-of-type(5) {
                width: 10%;
            }
        }

        tbody {
            tr {
                height: 120px;
                &.row {
                    border-top: 1px solid $black-alpha-0-25;
                    &:first-of-type {
                        border-top: 0;
                    }
                    &:hover {
                        background-color: $white-blue;
                    }
                }

                &.expanded-row {
                    border-top: 1px dashed $black-alpha-0-1;
                    height: 60px;
                }

                td {
                    &.name {
                        .content {
                            display: flex;
                            align-items: center;
                            .icon {
                                margin-right: 10px;
                                height: 40px;
                                width: 40px;
                            }

                            .name-container {
                                display: flex;
                                h3 {
                                    cursor: pointer;
                                }

                                .tooltip-container {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    padding-left: 0.5rem;

                                    .tooltip-image {
                                        width: 12px;
                                        margin-bottom: 0.5px;
                                    }

                                    > div {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }

                    &.labels,
                    &.tags  {
                        .content {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 1rem 0;
                            .chip {
                                font-weight: normal;
                                border-color: inherit;
                                color: inherit;
                            }
                        }

                        &.labels .content .chip {
                            color: $black-alpha-0-5;
                            border-color: $black-alpha-0-5;
                        }
                    
                        &.tags .content .chip {
                            color: $primary-brand-color;
                            border-color: $primary-brand-color;
                        }
                    }

                    &.status {
                        .content {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            .status-indicator {
                                width: 10px;
                                height: 10px;
                                border-radius: 10px;
                                margin-right: 5px;

                                &.SCHEDULED {
                                    background-color: $green;
                                }
                                &.DRAFT {
                                    background-color: $red;
                                }
                            }
                        }
                    }

                    &.actions {
                        .content {
                            display: flex;
                            flex-direction: row;
                            width: 100%;

                            button {
                                min-width: 32px;
                                // force transparent
                                background-color: rgba(0, 0, 0, 0);
                            }
                        }
                    }
                }
            }
        }
    }

    .edit-cancel-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px
    }

    a.btn-transparent {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        min-width: 2.5rem;
    }

    a.btn-transparent.disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }

    a.btn-transparent:focus {
        outline: 2px solid $edsights-blue;
        outline-offset: 2px;
        border-radius: 0.5rem;
    }
}
</style>
