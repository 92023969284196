<template>
    <div class="container">
        <div class="manage-students">
            <ButtonBack
                previousPageDisplayName="Manage Tags"
                previousPageRouteName="ManageTags"
                v-if="showBackButton"
            />
            <ScrollWrapper class="box">
                <List :title="title" :subtitle="subtitle" :loading="loading">
                    <template v-slot:selected-items-count>
                        <p>{{ studentsSelectedMsg }}</p>
                    </template>
                    <template v-slot:list-actions>
                        <SearchInput
                            v-model="params.search"
                            class="search"
                            placeholder="Search Students..."
                            is-small
                        />
                        <Button v-if="!hideButtons" class="btn-add" @click="uploadStudents"
                            >Add New</Button
                        >
                        <Button
                            v-if="!hideButtons"
                            style-type="off"
                            :disabled="selectedStudents.length === 0"
                            @click="removeSelectedStudents"
                            >Remove</Button
                        >
                    </template>
                    <LoadingSpinner v-if="loading" />
                    <template v-slot:list-header>
                        <ManageStudentsListHeader
                            class="header"
                            :selected="isMainCheckboxSelected"
                            @select="turnOnMainCheckbox"
                            @unselect="turnOffMainCheckbox"
                        />
                    </template>
                    <template v-slot:list-items>
                        <div>
                            <ManageStudentsListItem
                                v-for="student in students.list"
                                :key="student.id"
                                :student="student"
                                :selected="
                                    selectedStudents.find(item => item.id === student.id)
                                        ? true
                                        : false
                                "
                                @edit="onEdit"
                                @select="selectStudent"
                                @unselect="unselectStudent"
                            />
                            <Paginator
                                v-if="students.pagination.next != null"
                                :loading="students.loadingNextPage"
                                @next-page="students.addNextPage()"
                            />
                        </div>
                    </template>
                </List>
            </ScrollWrapper>
            <ModalAssignStudents @results="onReceivedResults" />
            <ModalLargeOperationResults />
        </div>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';
import List from '@/components-deprecated/tables/List';
import ManageStudentsListItem from '@/components-deprecated/tables/ManageStudentsListItem';
import ManageStudentsListHeader from '@/components-deprecated/tables/ManageStudentsListHeader';
import SearchInput from '@/components-deprecated/inputs/SearchInput';
import ModalAssignStudents from '@/components-deprecated/admin/ModalAssignStudents';
import ModalLargeOperationResults from '@/components-deprecated/admin/ModalLargeOperationResults';
import ScrollWrapper from '@/components-deprecated/ScrollWrapper';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import CollectionManager from '@/services/collectionManager';
import Pagination from '@/services/pagination';
import Student from '@/services/students';
import Paginator from '@/components-deprecated/Paginator';
import ButtonBack from '@/components-deprecated/ButtonBack.vue';

export default {
    name: 'ManageStudents',
    components: {
        Button,
        List,
        ManageStudentsListItem,
        ManageStudentsListHeader,
        SearchInput,
        ModalAssignStudents,
        ModalLargeOperationResults,
        ScrollWrapper,
        LoadingSpinner,
        Paginator,
        ButtonBack
    },
    props: {
        showBackButton: {
            type: Boolean,
            default: false
        }
    },
    elQuerySelectorToDetectScroll: '.manage-students .scroll-wrapper__elem',
    data() {
        return {
            title: 'Manage Students',
            subtitle: null,
            hideButtons: false,
            students: CollectionManager.create({
                ModelClass: Student
            }),
            selectedStudents: [],
            loading: false,
            params: {},
            pagination: Pagination.create(),
            isMainCheckboxSelected: false
        };
    },
    computed: {
        selectedStudentsCount() {
            return this.selectedStudents.length;
        },
        studentsSelectedMsg() {
            if (this.isMainCheckboxSelected) {
                return `${this.students.pagination.totalCount} student${
                    this.totalStudentsCount !== 1 ? 's' : ''
                } selected`;
            } else if (this.selectedStudentsCount) {
                const count = this.selectedStudentsCount;
                return `${count} student${count !== 1 ? 's' : ''} selected`;
            } else {
                return '';
            }
        }
    },
    watch: {
        selectedStudents() {
            this.isMainCheckboxSelected =
                this.selectedStudents.length === this.students.list.length &&
                this.students.list.length !== 0;
        },
        'params.search'() {
            this.loadStudents(this.params);
        }
    },
    async created() {
        this.loading = true;
        await this.loadStudents(this.params);
        this.loading = false;
    },
    methods: {
        onReceivedResults(results) {
            this.$modal.show('modal-large-operation-results', { results: results });
        },
        async refreshPage() {
            await this.loadStudents(this.params);
        },
        async loadStudents(params) {
            this.selectedStudents = [];
            this.students.filters = params;

            // filter by school for staff users.
            // otherwise, django sends back all schools' students
            if (this.$store.state.staffSchoolViewEnabled) {
                this.students.filters.school = this.$store.state.user.school;
            }

            await this.students.refresh();
        },
        onEdit(student) {
            this.$router.push({ name: 'StudentDetail', params: { id: student.id } });
        },
        onAddedStudents() {
            this.loadStudents(this.params);
        },
        turnOnMainCheckbox() {
            this.isMainCheckboxSelected = true;
            this.selectedStudents = [...this.students.list];
        },
        turnOffMainCheckbox() {
            this.isMainCheckboxSelected = false;
            this.selectedStudents = [];
        },
        selectStudent(student) {
            this.selectedStudents.push(student);
        },
        unselectStudent(student) {
            this.selectedStudents = this.selectedStudents.filter(item => item.id !== student.id);
        },
        uploadStudents() {
            //
        },
        async removeSelectedStudents() {
            const result = confirm('Are you sure you want to remove these students? ');
            if (result) {
                const studentIds = this.selectedStudents.map(student => student.id);
                try {
                    await Student.api.deactivate(studentIds);
                    this.$Alert.alert({
                        type: 'success',
                        message: `${studentIds.length} student(s) removed.`,
                        timeout: 7000
                    });
                } finally {
                    this.refreshPage();
                }
            }
        }
    }
};
</script>

<!--
    These styles are copied directly to the ManageStudentsByTag component which extends this component,
    because that component stopped being styled when we upgraded to Vue 3.
    It seems styling scoping has changed with extended components.
    Doing this for now to unblock us. We should figure out how to properly solve this or just get rid of this "extends" pattern
    as it's the only place in the app doing it.
-->
<style lang="scss" scoped>
.manage-students {
    padding-top: 50px;
}

.search {
    margin-right: 15px;
}

.btn-add {
    margin-right: 15px;
}

.header {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.filters {
    width: 190px;
    margin-right: 3rem;
    margin-top: 1rem;
}

.manage-students :deep(.scroll-wrapper__elem) {
    max-height: calc(100vh - 170px);
}

@media screen and (max-width: 1230px) {
    .students-list :deep(.list) {
        width: 75%;
    }
}
</style>
