<template>
    <div class="intervention-card">
        <div class="card-column" v-if="!isExperimentalAccessibilityEnabled">
            <img
                v-if="interventionTemplate.type === 'CHATBOT'"
                class="icon"
                src="@/assets/icons/robot.png"
                aria-hidden="true"
            />
            <img v-else class="icon" src="@/assets/icons/lightbulb.png" aria-hidden="true" />
        </div>
        <div class="card-column wide-column">
            <div class="card-row">
                <p class="card-header">{{ interventionTemplateType }}</p>

                <div class="stats-container">
                    <div class="intervention-progress">
                        <InterventionProgress :interventionTemplate="interventionTemplate" />
                    </div>
                    <div class="intervention-student-count">
                        {{ interventionTemplate.studentCount }} student(s) mentioned
                    </div>
                </div>
            </div>

            <div class="card-row intervention-description-row">
                {{ interventionTemplateText }}
            </div>
            <div class="card-row">
                <button
                    v-if="showDetailsLink"
                    :class="showDetailsLink && 'text-button'"
                    @click="showDetailsLink && navigateToDetails(interventionTemplate)"
                >
                    View intervention details
                </button>
                <div>
                    <p v-if="interventionTemplate.mostRecentInterventionDate">
                        Last Updated:
                        {{ getFormattedDate(interventionTemplate.mostRecentInterventionDate) }}
                    </p>
                    <p v-if="(chatbotFlow || {}).date">
                        Sent Date: {{ getFormattedDate(chatbotFlow.date) }}
                    </p>
                </div>

                <div v-if="showDownloadReport">
                    <ReportRequestDownloadButton
                        reportType="CHATBOT_CONVERSATIONS"
                        :reportOptions="reportOptions"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import momentTz from 'moment-timezone';
import InterventionProgress from '@/components-deprecated/InterventionProgress';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import ReportRequestDownloadButton from '@/components-deprecated/ReportRequestDownloadButton';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';
import { formatAccessibleString } from '@/lib/string-helpers';
import { setters } from '@/lib/app-cache';

export default {
    name: 'InterventionCard',

    components: {
        LoadingSpinner,
        InterventionProgress,
        ReportRequestDownloadButton
    },
    props: {
        interventionTemplate: {
            type: Object,
            default: null
        },
        showDetailsLink: {
            type: Boolean,
            default: false
        },
        showDownloadReport: {
            type: Boolean,
            default: false
        },
        reportOptions: {
            type: Object,
            required: false
        },
        chatbotTemplate: {
            type: Object,
            default: null
        },
        chatbotFlow: {
            type: Object,
            default: null
        },
        interventionIndex: {
            type: Number,
            default: 0
        },
        interventionCount: {
            type: Number,
            default: 0
        }
    },
    computed: {
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        },
        interventionTemplateType() {
            if (!this.interventionTemplate || !this.interventionTemplate.type) {
                return '';
            }

            if (this.interventionTemplate.type === 'CHATBOT') {
                return 'Action Taken by EdSights';
            } else {
                const header = 'Opportunity for Follow Up ✨';
                return formatAccessibleString(header, this.isExperimentalAccessibilityEnabled);
            }
        },
        interventionTemplateText() {
            if (!this.interventionTemplate || !this.interventionTemplate.renderedText) {
                return '';
            }

            return formatAccessibleString(
                this.interventionTemplate.renderedText,
                this.isExperimentalAccessibilityEnabled
            );
        }
    },
    methods: {
        getFormattedDate(timestamp) {
            if (timestamp) {
                const now = momentTz.utc().tz('America/New_York');
                const date = momentTz.utc(timestamp).tz('America/New_York');
                if (date.isSame(now, 'day')) {
                    return 'Today';
                }
                const yesterday = now.subtract(1, 'day');
                if (date.isSame(yesterday, 'day')) {
                    return 'Yesterday';
                }
                return date.format('YYYY-MM-DD');
            }
        },
        navigateToDetails(interventionTemplate) {
            setters.setInterventionDetailsContext(interventionTemplate.id, {
                chatbotTemplate: this.chatbotTemplate,
                chatbotFlow: this.chatbotFlow,
                interventionIndex: this.interventionIndex,
                interventionCount: this.interventionCount
            });

            this.$router.push({
                name: 'InterventionDetails',
                params: {
                    id: interventionTemplate.id
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.intervention-card {
    padding: 2rem;
    border: 1px solid $white-smoke;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.card-header {
    font-weight: bold;
    margin-left: -0.5rem;
}

.card-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
}

.wide-column {
    flex: 15;
}

.card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:last-child {
        margin-top: 2rem;
        color: $accessible-gray;
        font-size: 0.9rem;
        font-weight: bold;
    }
}

.icon {
    height: 40px;
    width: 40px;
    margin-right: 2rem;
}

.stats-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0rem 0.5rem;
    position: absolute;
    top: 1.25rem;
    right: 2rem;
    height: 2.2rem;

    .intervention-progress {
        margin-right: 1rem;
    }

    .intervention-student-count {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-dark;
        background-color: rgba($primary-dark, 0.1);
        font-size: 0.9rem;
        border-radius: 0.5rem;
        padding: 0 0.5rem;
        font-weight: bold;
    }
}

.intervention-description-row {
    margin-top: 0.25rem;
    width: 90%;
}

.download-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    margin-left: 3rem;
    color: $primary-brand-color;
}

.icon-download {
    stroke: $primary-brand-color;
    margin-left: 1rem;
}
</style>
